import { Fragment, useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { AsideFragment } from "../../../layouts/fragments/aside";
import { BackNavLink } from "../../../layouts/fragments/backnavlink";
import { ScreenOverlayFragment } from "../../../layouts/fragments/create";
import { Content, Page } from "../../../layouts/frame";
import { GetGroup } from "../../../lib/api";
import { Section } from "../../../lib/components/content/block";
import { TextareaInput } from "../../../lib/components/form/input";
import { ProgressiveImage } from "../../../lib/components/image";
import { SearchFragment } from "../../../layouts/fragments/search";
import { CommentFragment } from "../../../layouts/fragments/comment";
import Tab from "../../../lib/components/tab";

function FeedCard({profile, post}: {profile:any, post:any}) {
  return (
    <div className="mt-2 lg:max-w-[700px] border rounded items-center justify-center mx-auto p-2">
      <div className="flex">
        <div>
          <img className="w-16 rounded-full" src="https://lespetitespatounes.com/logo.png" />
        </div>

        <div className="ml-4">
          <p>
            <NavLink className="font-bold" to="/groups/profile">{profile.name}</NavLink> <span className="text-gray-500">{profile.tag} · {post.date}</span>
          </p>

          <p>
            {post.content}
          </p>
        </div>
      </div>

      {/* <div className="flex flex-1 rounded-lg border ml-14 mt-3 cursor-pointer">
        <div className="flex justify-center items-center py-8 px-12 bg-gray-100 border-r">
          <i className="fas fa-newspaper text-3xl text-gray-500"></i>
        </div>

        <div className="flex-1 flex flex-col justify-center py-8 pr-16 pl-4">
          <p>Joshua Welford</p>
          <p className="text-gray-500">Joshua Welford</p>
          <p className="text-gray-500"><i className="fas fa-link"></i> welford.me</p>
        </div>
      </div> */}

      <div className="flex justify-around mt-4 pl-3">
        <div className="text-gray-500 hover:text-blue-400 transition flex flex-row">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="size-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 20.25c4.97 0 9-3.694 9-8.25s-4.03-8.25-9-8.25S3 7.444 3 12c0 2.104.859 4.023 2.273 5.48.432.447.74 1.04.586 1.641a4.483 4.483 0 0 1-.923 1.785A5.969 5.969 0 0 0 6 21c1.282 0 2.47-.402 3.445-1.087.81.22 1.668.337 2.555.337Z" />
          </svg>
          {post.reply}
        </div>

        <div className="text-gray-500 hover:text-blue-400 transition flex flex-row">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12c0-1.232-.046-2.453-.138-3.662a4.006 4.006 0 0 0-3.7-3.7 48.678 48.678 0 0 0-7.324 0 4.006 4.006 0 0 0-3.7 3.7c-.017.22-.032.441-.046.662M19.5 12l3-3m-3 3-3-3m-12 3c0 1.232.046 2.453.138 3.662a4.006 4.006 0 0 0 3.7 3.7 48.656 48.656 0 0 0 7.324 0 4.006 4.006 0 0 0 3.7-3.7c.017-.22.032-.441.046-.662M4.5 12l3 3m-3-3-3 3" />
          </svg>
          {post.resent}

        </div>

        <div className="text-gray-500 hover:text-blue-400 transition flex flex-row">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z" />
          </svg>
    
          {post.like}
        </div>

        <div className="text-gray-500 hover:text-blue-400 transition flex flex-row">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M7.217 10.907a2.25 2.25 0 1 0 0 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186 9.566-5.314m-9.566 7.5 9.566 5.314m0 0a2.25 2.25 0 1 0 3.935 2.186 2.25 2.25 0 0 0-3.935-2.186Zm0-12.814a2.25 2.25 0 1 0 3.933-2.185 2.25 2.25 0 0 0-3.933 2.185Z" />
          </svg>


        </div>

        <div className="text-gray-500 hover:text-blue-400 transition flex flex-row">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0 0 20.25 18V6A2.25 2.25 0 0 0 18 3.75H6A2.25 2.25 0 0 0 3.75 6v12A2.25 2.25 0 0 0 6 20.25Z" />
          </svg>

        </div>
      </div>
    </div>
  )
}

export default function GroupDiscussPage() {
  const [group, setGroup] = useState<any>()
  const {id} = useParams()
  useEffect(() => {
    if(id){
      GetGroup({id}).then(setGroup)
    }
  }, [id])

  if(!group){
    return <Page>Chargement...</Page>
  }

  return (
    <>
      <CommentFragment placeholder="Exprimez-vous..." filterElement={() => <Fragment>
        
        </Fragment>} />

        <Section>
          {group.items.map((i:any) =>  <FeedCard key={i.id} profile={i.profile} post={i.post}/>)}
        </Section>

    </>
  )
} 
import { useCallback, useEffect, useState } from "react"
import { MapBox } from "../../lib/components/map/mapbox"
import { Capacitor } from "@capacitor/core"
import { Geolocation } from '@capacitor/geolocation';

export function MapContentFragment({ mainRef, features, center, zoom, onSelect }: { mainRef: any, features?: any[], center:any[], zoom: number, onSelect?: any }) {

    const [height, setHeight] = useState(0);
    const [width, setWidth] = useState(0);

    useEffect(() => {
        window.addEventListener("resize", onResize);
        onResize();
        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, [])

    const onResize = useCallback(() => {
        if (window) setHeight(window.innerHeight - 75);
        if (mainRef.current) setWidth(mainRef.current.clientWidth);
    }, []);


   

    return <>
        {mainRef && mainRef.current && <MapBox
            features={features}
            zoom={zoom}
            onSelectFeature={onSelect}
            style={{ height: height, width: width }}
            height={height}
            width={width}
            center={center}
            onRefetch={(c, zoom, bounds) => {

            }} />}

        {(!mainRef || !mainRef.current) && <div className="flex items-center justify-items-center align-middle" style={{
            background: "url('/img/empty_map.png') no-repeat center contain",
            height: height, width: width
        }}>
            <div className="flex font-bold px-4 items-center">
                <div className="font-bold p-2">Chargement de la carte...</div>
                {/* <div className="font-bold p-2 bg-white rounded"><button onClick={() => refresh()}>Actualiser</button></div> */}
            </div>
        </div>}
    </>
}
import { useCallback, useEffect, useState } from "react";

interface Props {
    isSM: boolean
    isMD: boolean
    isLG: boolean
    isXL: boolean
    is2XL: boolean
    isLarger: boolean
    isSmallScreen: boolean
    isLargeScreen: boolean
}
export function useViewPort() {
    const [vp, setVP] = useState<Props>({
        isSM: false,
        isMD: false,
        isLG: false,
        isXL: false,
        is2XL: false,
        isLarger: false,
        isSmallScreen: false,
        isLargeScreen: false,
    })

    const onResize = useCallback(() => setVP({
        isSM: window.innerWidth < 640,
        isMD: window.innerWidth >= 640 && window.innerWidth < 768,
        isLG: window.innerWidth >= 768 && window.innerWidth < 1024,
        isXL: window.innerWidth >= 1014 && window.innerWidth < 1280,
        is2XL: window.innerWidth >= 1280 && window.innerWidth < 1536,
        isLarger: window.innerWidth >= 1536,
        //  custom other
        isSmallScreen: window.innerWidth < 1024,
        isLargeScreen: window.innerWidth > 1024
    }), [window.innerWidth])

    useEffect(() => {
        window.addEventListener("resize", onResize);
        onResize();
        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, [])

    return vp
}
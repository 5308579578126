// map click handler
import {MapBrowserEvent} from "ol";
import {transform} from "ol/proj";
import {Coordinate} from "ol/coordinate";

const getMouseCoordinate = (event: MapBrowserEvent<UIEvent>): Coordinate => {

  // get clicked coordinate using mapRef to access current React state inside OpenLayers callback
  //  https://stackoverflow.com/a/60643670
  const clickedCoord = event.map.getCoordinateFromPixel(event.pixel);

  // transform coord to EPSG 4326 standard Lat Long
  return transform(clickedCoord, 'EPSG:3857', 'EPSG:4326')
}

const transformEPSG4326 = (coordinates: Coordinate) => {
  return transform(coordinates, 'EPSG:3857', 'EPSG:4326')
}

export {
  getMouseCoordinate,
  transformEPSG4326
}

import { get } from "./fetch";
import { Item, Lodging } from "./types";

export const GetLodgings = () => get<any[]>(`/lodgings`, undefined)
export const GetLodging = ({id}: { id: string }) => get<Lodging[]>(`/lodgings/${id}`, undefined)
export const GetLodgingFilters = () => get<Item[]>(`/lodgings/filters`, undefined)
export const GetLodgingCategories = () => get<{[key:string]: Item[]}>(`/lodgings/categories`, undefined)

// LEGACY
export const GetLodgingCategoriesPets = () => get<Item[]>(`/lodgings/categories/pets`, [])
export const GetLodgingCategoriesTypes = () => get<Item[]>(`/lodgings/categories/types`, [])
export const GetLodgingCategoriesEquipments = () => get<Item[]>(`/lodgings/categories/equipments`, [])


// export const GetLodging = ({id}: { id: string }) => get<Lodging | undefined>(`/v1/lodgings/search/${id}`, undefined
// )
// export const SearchLodgings = (body: LodgingSearchRequest, qp?: any) => post<ResultList<Lodging>>(`/v1/lodgings/search${qp ? '?' + serialize(qp) : ''}`, body, {
//   meta: undefined,
//   items: []
// })


import { createPortal } from "react-dom";
import { Page } from "../../layouts/frame";
import { Section } from "../../lib/components/content/block";
import { useOutletContext } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";
import { BottomFilterFragment, TopFilterFragment } from "../../layouts/fragments/filter";
import { AsideFragment } from "../../layouts/fragments/aside";
import { ScreenOverlayFragment } from "../../layouts/fragments/create";
import { TextareaInput } from "../../lib/components/form/input";
import { Card } from "../../lib/components/content/card";
import { useEffect, useState } from "react";
import { GetEvents, GetExplore } from "../../lib/api";
import { Grid, GridCard } from "../../lib/components/image/gridimage";
import { Title } from "../../lib/components/typo";



export default function ExplorePage() {

  const [explore, setExplore]  = useState<any>()

  useEffect(()=>{
    GetExplore().then(setExplore)
  }, [])

  if (!explore || !explore.pages){
    return (<Page>Chargement...</Page>) 
  }
  return (
    <Page>
      {explore.pages && <Section>
      <Title>{explore.pages.title}</Title>
          <Grid>
            {explore.pages.items?.map((p:any) => <GridCard img={p.page_media_url} badge={p.page_title} />)}
          </Grid>
      </Section>}


      {explore.lodgings && <Section>
      <Title>{explore.lodgings.title}</Title>
          <Grid>
            {explore.lodgings.items?.map((p:any) => <GridCard img={p.lodging_media_url} badge={p.lodging_title} />)}
          </Grid>
      </Section>}

      {explore.services && <Section>
      <Title>{explore.services.title}</Title>
          <Grid>
            {explore.services.items?.map((p:any) => <GridCard img={p.media_url} badge={p.service_title} />)}
          </Grid>
      </Section>}
      {/* <BottomFilterFragment>
        <button type="button" className="px-5 py-1.5 text-xs font-medium text-gray-900 hover:bg-gray-200 dark:text-white dark:hover:bg-gray-700 rounded-lg">
          New
        </button>
        <button type="button" className="px-5 py-1.5 text-xs font-medium text-white bg-gray-900 dark:bg-gray-300 dark:text-gray-900 rounded-lg">
          Popular
        </button>
        <button type="button" className="px-5 py-1.5 text-xs font-medium text-gray-900 hover:bg-gray-200 dark:text-white dark:hover:bg-gray-700 rounded-lg">
          Following
        </button>
      </BottomFilterFragment> */}
      {/* <TopFilterFragment>
        <button type="button" className="px-5 py-1.5 text-xs font-medium text-gray-900 hover:bg-gray-200 dark:text-white dark:hover:bg-gray-700 rounded-lg">
          A
        </button>
        <button type="button" className="px-5 py-1.5 text-xs font-medium text-white bg-gray-900 dark:bg-gray-300 dark:text-gray-900 rounded-lg">
          B
        </button>
        <button type="button" className="px-5 py-1.5 text-xs font-medium text-gray-900 hover:bg-gray-200 dark:text-white dark:hover:bg-gray-700 rounded-lg">
          C
        </button>
      </TopFilterFragment> */}
      <AsideFragment>
        
        {/* <div className="relative text-gray-300 w-80 p-5 bg-gray-300 rounded">
          <button type="submit" className="absolute ml-4 mt-3 mr-4">
            <svg className="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 56.966 56.966" width="512px" height="512px">
              <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z"></path>
            </svg>
          </button>

          <input type="search" name="search" placeholder="Rechercher" className=" bg-dim-700 h-10 px-10 pr-5 w-full rounded-full text-sm focus:outline-none bg-purple-white shadow rounded border-0" />
        </div> */}

      </AsideFragment>

      <ScreenOverlayFragment title=''>
        <Section>
        <TextareaInput/>
        </Section>

      </ScreenOverlayFragment> 
    </Page>
  )
} 
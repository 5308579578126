import { createElement } from "react"
import { GridCard, GridCardText } from "../../lib/components/image/gridimage"
import { Badge, BadgeIndicator } from "../../lib/components/badge"

export enum FragmentType {
    Card,
    CardElement,
    ListItem,
    Badge,
}
export function DynamicFragment(type: FragmentType, props: any): any {
    let el: any
    switch (type) {
        case FragmentType.Card:
            el = GridCard
            break
        case FragmentType.CardElement:
            el = GridCardText
            break
        case FragmentType.Badge:
            el = Badge
            break
        default:
            console.error('failed to find dynamic element', type, props)
            el = <>Oups! Element non trouvé</>
    }
    return createElement(el, props)
}
import { Capacitor } from "@capacitor/core";
import { Geolocation, Position } from "@capacitor/geolocation";

export const GetGeolocation = async (callback: (position: Position | null, err?: any) => void) => {
    try {
        console.log('Capacitor.getPlatform():', Capacitor.getPlatform())
        const checkedPerms = await Geolocation.checkPermissions();
        console.log('Geolocation.checkPermissions()', checkedPerms);

        if (Capacitor.getPlatform() !== 'web') {
            const perms = await Geolocation.requestPermissions();
            console.log('Geolocation.requestPermissions()', perms);
        }

        return Geolocation.watchPosition({ enableHighAccuracy: true }, (position: Position | null, err?: any) => {
            console.log('Geolocation watch position callback', position, err)
            if (err) {
                console.error('Geolocation watch position callback error', position, err)
            }

            if (!position) {
                console.warn('Geolocation watch position callback no position', position, err)
            }

            callback(position, err)
        })

    } catch (e) {
        console.log('failed to active location error:', e)
    }
}

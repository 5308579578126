import classNames from "classnames"
import { Icon } from "../lib/components/icon"
import { NavLink } from "react-router-dom"
import { GlobeEuropeAfricaIcon, HomeIcon, PlusIcon, FireIcon, MapPinIcon, HomeModernIcon, CalendarIcon, NewspaperIcon, MegaphoneIcon, TvIcon, UserGroupIcon, ShoppingBagIcon, Bars3Icon, EllipsisHorizontalCircleIcon } from '@heroicons/react/24/outline'
import { ToolBar } from "../lib/components/nav/toolbar"
import { createPortal } from "react-dom"
import { useEffect, useState } from "react"
import { GetMenu } from "../lib/api"


export interface MenuItem {
  name?: string,
  href: string,
  key: string,
  icon?: any,
  separator?: boolean,
  roles?: string[]
  expanded?: boolean
  items?: MenuItem[]
}

export function MenuFragment({toolbar}: {toolbar: any}) {
  const [menu, setMenu] = useState<any>([])
  const [leftButtons, setLeftButtons] = useState<any>([])
  const [rightButtons, setRightButtons] = useState<any>([])
  useEffect(()=> {
    GetMenu().then(s => {

      if (!Array.isArray(s)){
        return
      }
      setMenu(s)
      setLeftButtons(s.filter((i:any) => i.toolbar == 1 || i.toolbar == 2))
      setRightButtons([
        s.find((i:any) => i.toolbar == 3),
        { href: '/more', icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon" height="24"><path stroke-linecap="round" stroke-linejoin="round" d="M8.625 12a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path></svg>` },
      ])
    })
  },[])

  const toolbarEls = <ToolBar
  leftButtons={leftButtons}
  rightButtons={rightButtons}
  
  />
  return (
    <>
     {
        toolbar && createPortal(toolbarEls, toolbar)
     }

      <header className="pl-4 md:pl-2 relative w-full">
 
        {/* <h4 className="text-lg pt-2 font-bold">Thématiques </h4> */}
        <ul>
          <li>
            {menu && menu.map(((it:any) => {
              const allowed = true //it.roles ? profile && hasRequiredRoles(it.roles, profile.roles ?? []) : true
              return allowed && <div key={'m-i-' + (it.name ?? it.key)}>
                <div className="flex flex-row w-full justify-end">
                  <div className="grow"><MenuLink to={it.href} >
                      <span className="w-6"><i dangerouslySetInnerHTML={{__html: it.icon}}/></span>
                    {it.name ?? it.key}
                  </MenuLink>
                  {it.separator &&  <hr className="border-t-gray-300  m-1 mx-auto w-40 " />}
                  </div>
                </div>
              </div>
            }))}
          </li>
        </ul>
        <hr className="border-t-gray-300  m-1 mx-auto w-40 " />
       
        <p className="text-xs text-gray-500 text-center pt-3">
          <MenuLink className=" text-gray-500 inline-block text-sm pl-2" title="Presse"
            href="/pages/contact-presse">Presse</MenuLink>
          <MenuLink className=" text-gray-500 inline-block text-sm pl-2" title="A propos"
            href="/pages/a-propos">A propos</MenuLink>
          <MenuLink className=" text-gray-500 inline-block text-sm pl-2" title="Tarifs"
            href="/pages/pricing">Tarifs</MenuLink>
          <MenuLink className=" text-gray-500 inline-block text-sm pl-2" title="Conditions Générales d'Utilisation"
            href="/pages/conditions-generales-utilisation">CGU</MenuLink>
          <MenuLink className=" text-gray-500 inline-block text-sm pr-2" title="Conditions Générales de Vente"
            href="/pages/conditions-generales-vente">CGV</MenuLink><br />
          &copy; 2024 Furry Journey <br /> Tous droits réservés <br />
        </p>
      </header>

      
    </>
  )

}


export const MenuLink = (props: any) => <NavLink {...props} to={props.to ?? props.href}
  className={({ isActive, isPending }) => classNames("flex flex-row items-center p-2 m-2 hover:bg-gray-200 rounded", { 'bg-blue-100': isActive }, props.className)} />
import { NavLink } from "react-router-dom";
import { ProgressiveImage } from "../image";
import { ButtonOutline } from "../button";

export function List(props: any){
    return <ul className="divide-y divide-gray-200 dark:divide-gray-700" {...props}/>
}
export function ListItem(props : {href: string, img: string, title: string, subtitle: string , actions: any[]}) {
    return (
      <li className="py-1">
        <NavLink to={props.href} className="flex items-center space-x-4 rtl:space-x-reverse">
          <div className="flex-shrink-0">
            <ProgressiveImage className="rounded-lg" src={props.img} alt="image description" {...props} height={45} width={45}/>
          </div>
          <div className="flex-1 min-w-0">
            <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
              {props.title}
            </p>
            <p className="text-sm text-gray-500 truncate dark:text-gray-400">
              {props.subtitle}
            </p>
          </div>
          <div className="flex items-center justify-center">
            {props.actions && props.actions.map((a:any) =>  <ButtonOutline>{a.label}</ButtonOutline>)}
          </div>
        </NavLink>
      </li>
    )
  }
  
import { useEffect } from "react";
import { BackNavLink } from "../../layouts/fragments/backnavlink";
import { Content, Page } from "../../layouts/frame";
import { useParams } from "react-router-dom";
import { GetLodging } from "../../lib/api";


export function LodgingDetailPage() {
    const { id } = useParams()
    useEffect(() => {
        console.log('lodging id', id)
        if (id) {
            GetLodging({ id }).then((r) => {
                console.log('lodging details', r)
            })
        } else {
            window.location.href = "/"
        }
    }, [id])
    return <Page>
        <Content>
            <BackNavLink to="/lodgings" />
            Hébermegents
        </Content>
    </Page>
}
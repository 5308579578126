import { NavLink, useOutletContext } from "react-router-dom";
import classNames from "classnames";
import { PlusIcon, EllipsisHorizontalIcon } from '@heroicons/react/24/outline'
import { useCallback, useState } from "react";
import { eventBus } from "../../../../layouts/frame";

interface ToolbarItem {
    href: string,
    icon: any

}
export interface ToolbarProps {
    leftButtons: ToolbarItem[]
    rightButtons: ToolbarItem[]
}

export function ToolBar(props: ToolbarProps) {
    // const {setScreenOverlayVisible } = useOutletContext<any>()


    const renderButton  = useCallback((b: ToolbarItem)=>(
        <NavLink key={b.href} to={b.href} type="button" className={({isActive, isPending}) => classNames(
            "inline-flex flex-col items-center justify-center p-4 hover:bg-gray-50 dark:hover:bg-gray-800 group", {'bg-blue-100 hover:bg-blue-100': isActive}, {'bg-red-500': isPending})}>
            <i dangerouslySetInnerHTML={{__html: b.icon}}/>
            <span className="sr-only">{b.href}</span>
        </NavLink>
    ), [])


    const openScreenOverlay = ()=>{
        console.log("open screen overlay")
        eventBus.next('screen-overlay:open')
    }

    return (
        <>
            <div className="xl:hidden w-full p-[28px]"></div>
            <div className="xl:hidden fixed bottom-0 z-50 w-full -translate-x-1/2 bg-white border-t border-gray-200 left-1/2 dark:bg-gray-700 dark:border-gray-600">
                <div className="grid h-full max-w-lg grid-cols-5 mx-auto">
                    {props.leftButtons.map(b => renderButton(b))}
                    <button onClick={()=>openScreenOverlay()} className={classNames("inline-flex flex-col items-center justify-center p-0  text-blue-600 z-10 group")}>
                        <PlusIcon height={32}/>
                    </button>
                    {props.rightButtons.map(b => renderButton(b))}
                    {/* {renderButton(props.buttons[0])}
                    {renderButton(props.buttons[1])}
                    {renderButton({href:'', icon: PlusIcon})}
                    {renderButton(props.buttons[2])}
                    {renderButton(props.buttons[3])} */}


                </div>
            </div>

        </>
    )
}
import { ReactElement, useCallback, useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { ControlledForm } from "../../lib/components/form";
import { TextInput } from "../../lib/components/form/input";
import { PhotoIcon, FaceSmileIcon, MapPinIcon} from "@heroicons/react/24/outline";
import classNames from "classnames";

interface FormValues { email: string, password: string, token: string }

export function CommentFragment({placeholder, floating, parent, filterElement}: {placeholder: string, filterElement: (form: UseFormReturn<FormValues, any, undefined>)=>ReactElement, floating?:boolean, parent?:any}) {
    const form = useForm<FormValues>()
    const [isFilterVisible, setFilterVisible] = useState(false)

    const handleSubmit = useCallback((data: FormValues) => {
        console.log('execute comment', data)
        // executeRecaptcha("form_login")
        //   .then(token => login({...data, token: token}))
        //   .then((r: any) => {
        //     console.log('execute login response')
        //     if (r && !r.error) {
        //       window.location.href = '/account'
        //     }
        //   })
        //   .catch((e: any) => {
        //     const err = parseError(e)
        //     if (err == errors.confirm_email) {
        //       window.location.href = `/confirm-account?email=${data.email}`
        //       return
        //     }
        //   })
    }, []); //executeRecaptcha, login

    
    
    return <div className={classNames({"absolute z-10 bg-white rounded": floating})} style={{width: floating? parent?.current?.clientWidth : ''}}>
        <ControlledForm useForm={form} onSubmit={form.handleSubmit(handleSubmit)}>
            <div className={'flex flex-row justify-center items-center p-2 max-w-160 m-auto'}>
                <TextInput useForm={form} required={true} placeholder={placeholder} name={"search"} />
                <PhotoIcon height={40} onClick={()=>setFilterVisible(v => !v)} className="hover:bg-gray-200 rounded-full cursor-pointer p-2"/>
                <FaceSmileIcon height={40} onClick={()=>setFilterVisible(v => !v)} className="hover:bg-gray-200 rounded-full cursor-pointer p-2"/>
                <MapPinIcon height={40} onClick={()=>setFilterVisible(v => !v)} className="hover:bg-gray-200 rounded-full cursor-pointer p-2"/>
            </div>

            {<div className={classNames("bg-gray-200 rounded h-0 overflow-hidden transition-height duration-500 ease-in-out", {"h-[400px]": isFilterVisible})}>
                {filterElement(form)}
            </div>}
        </ControlledForm>
    </div>
}
import { Grid, GridCard } from "../../lib/components/image/gridimage";
import { MapPinIcon, CalendarIcon, LinkIcon} from '@heroicons/react/24/outline'
import Tab from "../../lib/components/tab";
import { Content, Page } from "../../layouts/frame";

export function ProfileCard(){
    return(
        <>
        <div className="flex justify-between">
      <div className="rounded-full border-4 border-white inline-block -mt-16 w-32 ml-3">
        <img className="w-32 rounded-full" src="https://lespetitespatounes.com/logo.png" />
      </div>
      
      <div>
        <button className="rounded-full px-3 py-2 mt-3 mr-3 transition hover:bg-blue-50 inline-block border-2 border-blue-400 text-blue-400 font-bold">Edit Profile</button>
      </div>
    </div>
    
    <div className="ml-3">
      <p className="font-bold text-lg">Josh W</p>
      <p className="text-gray-500">@welfordian</p>
    </div>
    
    <div className="px-3 mt-3">
      <p>
        DHIS2. Debian. React. Vue. Gatsby. PWAs. ES6. Node. Electron. Tailwind. Working with a UK charity to bring EMRs to the Developing World.
      </p>
    </div>
    
    <div className="px-3 flex mt-3">
      <div className="flex items-center">
        <i className="fas fa-map-marker-alt"></i>
        <MapPinIcon height={18}/>
        <p className="ml-2">Frederick, MD</p>
      </div>
      
      <div className="flex items-center ml-3">
        <LinkIcon height={18}/>
        <p className="ml-2">
          <a className="text-blue-500 hover:underline" target="_blank" rel="noopener" href="https://welford.me">welford.me</a>
        </p>
      </div>
      
      <div className="flex items-center ml-3">
        <CalendarIcon height={18}/>
        <p className="ml-2">Joined June 2012</p>
      </div>
    </div>
    
    <div className="px-3 mt-3 flex">
      <p className="cursor-pointer hover:underline"><span className="font-bold">679</span> Following</p>
      <p className="ml-5 cursor-pointer hover:underline"><span className="font-bold">679</span> Following</p>
    </div>
    </>
    )
}
export function UserProfilePage(){
    return (
        <Page>
            <Content>
              <ProfileCard/>
              <Tab items={[{label:'Discussion', href: '/profile/'}, {label:'Galerie', href: '/group'}, {label:'Membres', href: '/group'}, {label:'À propos', href: '/group'}]}/>

              <Grid>
              <GridCard  img="https://static.lespetitespatounes.com/events/5a555d29-b79b-4476-8f2f-9e16574b87c1/pictures/8e5d85ce-e46d-11ee-80ee-4e7db3d247d3.jpg"   badge="Festivals"/>
              <GridCard  img="https://static.lespetitespatounes.com/events/d9697aa2-5725-41fa-af72-1663f7795a73/pictures/93422ee4-e46f-11ee-a14f-4e7db3d247d3.jpg" badge="Activités sportives" />
              <GridCard  img="https://static.lespetitespatounes.com/events/802f2df4-d4dd-400f-85dc-c681367d8565/pictures/1c8e9b16-e46f-11ee-b00c-4e7db3d247d3.jpg" badge="Salons et Expositions" />
              <GridCard  img="https://static.lespetitespatounes.com/events/5a555d29-b79b-4476-8f2f-9e16574b87c1/pictures/8e5d85ce-e46d-11ee-80ee-4e7db3d247d3.jpg"   badge="Festivals"/>
              <GridCard  img="https://static.lespetitespatounes.com/events/d9697aa2-5725-41fa-af72-1663f7795a73/pictures/93422ee4-e46f-11ee-a14f-4e7db3d247d3.jpg" badge="Activités sportives" />
              <GridCard  img="https://static.lespetitespatounes.com/events/802f2df4-d4dd-400f-85dc-c681367d8565/pictures/1c8e9b16-e46f-11ee-b00c-4e7db3d247d3.jpg" badge="Salons et Expositions" />
              <GridCard  img="https://static.lespetitespatounes.com/events/5a555d29-b79b-4476-8f2f-9e16574b87c1/pictures/8e5d85ce-e46d-11ee-80ee-4e7db3d247d3.jpg"   badge="Festivals"/>
              <GridCard  img="https://static.lespetitespatounes.com/events/d9697aa2-5725-41fa-af72-1663f7795a73/pictures/93422ee4-e46f-11ee-a14f-4e7db3d247d3.jpg" badge="Activités sportives" />
              <GridCard  img="https://static.lespetitespatounes.com/events/802f2df4-d4dd-400f-85dc-c681367d8565/pictures/1c8e9b16-e46f-11ee-b00c-4e7db3d247d3.jpg" badge="Salons et Expositions" />
              <GridCard  img="https://static.lespetitespatounes.com/events/5a555d29-b79b-4476-8f2f-9e16574b87c1/pictures/8e5d85ce-e46d-11ee-80ee-4e7db3d247d3.jpg"   badge="Festivals"/>
              <GridCard  img="https://static.lespetitespatounes.com/events/d9697aa2-5725-41fa-af72-1663f7795a73/pictures/93422ee4-e46f-11ee-a14f-4e7db3d247d3.jpg" badge="Activités sportives" />
              <GridCard  img="https://static.lespetitespatounes.com/events/802f2df4-d4dd-400f-85dc-c681367d8565/pictures/1c8e9b16-e46f-11ee-b00c-4e7db3d247d3.jpg" badge="Salons et Expositions" />
              <GridCard  img="https://static.lespetitespatounes.com/events/5a555d29-b79b-4476-8f2f-9e16574b87c1/pictures/8e5d85ce-e46d-11ee-80ee-4e7db3d247d3.jpg"   badge="Festivals"/>
              <GridCard  img="https://static.lespetitespatounes.com/events/d9697aa2-5725-41fa-af72-1663f7795a73/pictures/93422ee4-e46f-11ee-a14f-4e7db3d247d3.jpg" badge="Activités sportives" />
              <GridCard  img="https://static.lespetitespatounes.com/events/802f2df4-d4dd-400f-85dc-c681367d8565/pictures/1c8e9b16-e46f-11ee-b00c-4e7db3d247d3.jpg" badge="Salons et Expositions" />
          
                </Grid>
            </Content>
        </Page>
    )
}
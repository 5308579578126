import {get, hasAuthentication, post} from "./fetch";
import {Account, User} from "./types";

export const GetUsers = () => get<User[]>(`/users`, [])
export const LoginUser = (data:any) => post<any>(`/users/login`, data)
export const RegisterUser = (data:any) => post<any>(`/users/register`, data)


// export const GetLoggedAccount = () => {
//   return hasAuthentication().then(_ => get<Account | undefined>(`/v1/iam/accounts/me`, undefined)).catch(_ => undefined)
// }

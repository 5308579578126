import { Fragment } from "react/jsx-runtime";
import { AsideFragment } from "../../layouts/fragments/aside";
import { ScreenOverlayAction, ScreenOverlayFragment } from "../../layouts/fragments/create";
import { BottomFilterFragment } from "../../layouts/fragments/filter";
import { SearchFragment } from "../../layouts/fragments/search";
import { Content, Page } from "../../layouts/frame";
import { Button } from "../../lib/components/button";
import { Section } from "../../lib/components/content/block";
import { TextareaInput } from "../../lib/components/form/input";
import { Grid, GridCard } from "../../lib/components/image/gridimage";
import { SubTitle, Title } from "../../lib/components/typo";
import { useEffect, useState } from "react";
import { GetExplore, GetPages } from "../../lib/api";
import { GetServices } from "../../lib/api/services";

export default function PagesPage() {

  const [pages, setPages] = useState<any>()

  useEffect(() => {
    GetPages().then(setPages)
  }, [])

  if (!pages) {
    return (<Page>Chargement...</Page>)
  }


  return (
    <Page>
      <Content>
        <SearchFragment placeholder="Rechercher un page" filterElement={() => <Fragment>
          {pages.filters.map((f: any)=> <div>
            {f.label}
            <input placeholder={f.type}/>
          </div>)}
        </Fragment>} />
        {pages.sections.map((section: any) => <Section >
          <Title>{section.title}</Title>
          <Grid>
            {section.items.map((s: any) => <GridCard img={s.page_media_url} badge={s.page_title} href={'/pages/'+s.page_id} />)}
          </Grid>
        </Section>
        )
        }


      </Content>
      {/* <BottomFilterFragment>
        <button type="button" className="px-5 py-1.5 text-xs font-medium text-gray-900 hover:bg-gray-200 dark:text-white dark:hover:bg-gray-700 rounded-lg">
          Parcs
        </button>
        <button type="button" className="px-5 py-1.5 text-xs font-medium text-white bg-gray-900 dark:bg-gray-300 dark:text-gray-900 rounded-lg">
          Services
        </button>
        <button type="button" onClick={() => { }} className="px-5 py-1.5 text-xs font-medium text-gray-900 hover:bg-gray-200 dark:text-white dark:hover:bg-gray-700 rounded-lg">
          Autour
        </button>
      </BottomFilterFragment> */}


      <AsideFragment>
        <Section>
          <ScreenOverlayAction>
            <Button>Publier un service</Button>
          </ScreenOverlayAction>
        </Section>
      </AsideFragment>

      <ScreenOverlayFragment title='Publier un service'>

        <Section>
          <div className="border p-3 text-blue-700">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Emplacement</label>
            (O) Position courante
          </div>
        </Section>

        <Section>
          <div className="border p-3 ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Catégorie</label>
            (O) Pet Sitter
          </div>
        </Section>

        <Section>
          <div className="border p-3 ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Ajouter des photos</label>
            (O) Cliquez ici
          </div>
        </Section>

        <Section>
          <TextareaInput />
        </Section>

        <Button>Publier</Button>
      </ScreenOverlayFragment>
    </Page>

  )
} 
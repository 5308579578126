import { Fragment, useEffect, useState } from "react";
import { BottomFilterFragment } from "../../layouts/fragments/filter";
import { Content, Page } from "../../layouts/frame";
import { MapBox } from "../../lib/components/map/mapbox";
import { Geolocation } from '@capacitor/geolocation';
import { useOutletContext } from "react-router-dom";
import { ScreenOverlayAction, ScreenOverlayFragment } from "../../layouts/fragments/create";
import { Section } from "../../lib/components/content/block";
import { TextareaInput } from "../../lib/components/form/input";
import { Capacitor } from "@capacitor/core";
import { Button, ToggleButton } from "../../lib/components/button";
import { AsideFragment } from "../../layouts/fragments/aside";
import { SearchFragment } from "../../layouts/fragments/search";
import { MapIcon, QueueListIcon } from "@heroicons/react/24/outline";
import { MapContentFragment } from "../../layouts/fragments/map";
import { Feature } from "ol";
import { GetPlaces, GetPlacesFilters } from "../../lib/api";
import { FeatureType, FeatureTypeKey, NewPoint } from "../../lib/components/map/ol";
import { MapPopupCard } from "../../lib/components/image/gridimage";
import { PlacesListFragment } from "./fragments/list";

export default function PlacesPage() {
  const { mainRef } = useOutletContext<any>()
  const [sections, setSections] = useState<any>()
  const [petCategories, setPetCategories] = useState<any>()
  const [typeCategories, setTypeCategories] = useState<any>()
  const [filters, setFilters] = useState<any>()
  const [isMapVisible, setMapVisible] = useState(true)
  const [features, setFeatures] = useState<Feature[]>([])

  useEffect(() => {
    GetPlaces().then((v: any) => {
      setSections(v)
      for (const section of v.sections) {
        setFeatures(section.items.map((i: any) => NewPoint(i.id, [i.location.address.lng, i.location.address.lat], { ...i, "selected": "", [`${FeatureTypeKey}`]: FeatureType.PLACE })))
      }
    })
    GetPlacesFilters().then(setFilters)

  }, [])


  if (!mainRef) {
    return <Page>Chargement..</Page>
  }

  return (
    <Page>
      <Content>
        {filters && <SearchFragment parent={mainRef} floating={false} placeholder={filters.placeholder} buttons={
          <ToggleButton ofElement={MapIcon} onElement={QueueListIcon} active={isMapVisible}
            height={40}
            title={isMapVisible ? "Vue liste" : "Vue carte"} className="hover:bg-gray-200 rounded-full cursor-pointer p-2"
            onClick={() => setMapVisible(v => !v)} />
        } filterElement={() => <Fragment>
          {filters.filters.map((f: any) => <div>
            {f.label}
            <input placeholder={f.type} />
          </div>)}
        </Fragment>} />}

        {mainRef && isMapVisible && sections ? (
        <MapContentFragment mainRef={mainRef} features={features} center={[sections.meta.center_lng, sections.meta.center_lat]} zoom={sections.meta.zoom}
            onSelect={(f: Feature, all: any) => {

              setFeatures(features.map(feat => {
                if (feat.getId() == f.getId()) {
                  feat.setProperties({ ...feat.getProperties(), 'selected': 'selected' });
                }
                return feat
              }))

              const p = f.getProperties()

              console.log('selected feature', p)
              return <MapPopupCard img={p.media_url} badge={p.type}
                line1={p.title}
                line2={p.location.address.street}
                line3={p.location.department.name + ', ' + p.location.city.name}
                href={"/places/" + p.id}
              />
            }} />
          // </div>
          // </div>
        ) :           <PlacesListFragment filters={filters} sections={sections} categories={petCategories} />}
      

      {/* <BottomFilterFragment>
        <button type="button" className="px-5 py-1.5 text-xs font-medium text-gray-900 hover:bg-gray-200 dark:text-white dark:hover:bg-gray-700 rounded-lg">
          Parcs
        </button>
        <button type="button" className="px-5 py-1.5 text-xs font-medium text-white bg-gray-900 dark:bg-gray-300 dark:text-gray-900 rounded-lg">
          Services
        </button>
        <button type="button" onClick={() => refresh()} className="px-5 py-1.5 text-xs font-medium text-gray-900 hover:bg-gray-200 dark:text-white dark:hover:bg-gray-700 rounded-lg">
          Autour
        </button>
      </BottomFilterFragment> */}
      </Content>
      <AsideFragment>
        <Section>
          <ScreenOverlayAction>
            <Button>Publier sur un lieu</Button>
          </ScreenOverlayAction>
        </Section>
      </AsideFragment>

      <ScreenOverlayFragment title='Publier sur un lieu'>

        <Section>
          <div className="border p-3 text-blue-700">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Emplacement</label>
            (O) Position courante
          </div>
        </Section>

        <Section>
          <div className="border p-3 ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Catégorie</label>
            (O) Fontaine à eau
          </div>
        </Section>

        <Section>
          <div className="border p-3 ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Ajouter des photos</label>
            (O) Cliquez ici
          </div>
        </Section>

        <Section>
          <TextareaInput />
        </Section>

        <Button>Envoyer</Button>
      </ScreenOverlayFragment>
    </Page>

  )
} 
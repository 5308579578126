import { ButtonHTMLAttributes, InputHTMLAttributes } from "react"
import { ControlledProps, Field, Helper, Label } from ".."
import { Button, GenericButton } from "../../button"
import { Controller } from "react-hook-form"


function getRules(label?: string, required?: boolean) {
    return {required: required ? 'champs requis' : undefined};
}

export function GenericInput({useForm, ...props}:ControlledProps<InputHTMLAttributes<HTMLElement>>) {
    const {formState: {errors}, control} = useForm
    return (
        <Field>
            <Label  useForm={useForm} {...props}/>
            <Controller name={props.name ?? "undefined"} defaultValue={props.defaultValue || ''} control={control} rules={getRules(props.label, props.required)}
                render={({field, fieldState}) => (<input  {...props} id={field.name} {...field} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                )}/>
            <Helper useForm={useForm} {...props}/>
        </Field>
    )
}

export function TextInput(props:ControlledProps<InputHTMLAttributes<HTMLElement>>) {
    return <GenericInput {...props} type="text"/>
}

export function PasswordInput(props:ControlledProps<InputHTMLAttributes<HTMLElement>>) {
    return <GenericInput {...props} type="password"/>
}

export function TextareaInput(props:any) {
    return (

        <form className="max-w-sm mx-auto">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your message</label>
            <textarea id="message" rows={4} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Leave a comment..."></textarea>
        </form>

    )
}



export function SubmitButton(props:ControlledProps<ButtonHTMLAttributes<HTMLElement>>){
    return <GenericButton type="submit">{props.label}</GenericButton>
}


export function SelectInput() {
    return (

        <form className="max-w-sm mx-auto">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select your country</label>
            <select id="countries" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">

                <option>United States</option>
                <option>Canada</option>
                <option>France</option>
                <option>Germany</option>
            </select>
        </form>

    )
}

export function CheckboxInput(props:ControlledProps<InputHTMLAttributes<HTMLElement>>) {
    return (
        <fieldset>
            <legend className="sr-only">Checkbox variants</legend>

            <div className="flex mb-4">
                <div className="flex items-center h-5">
                    <input id="helper-checkbox" aria-describedby="helper-checkbox-text" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                </div>
                <div className="ms-2 text-sm">
                    <label className="font-medium text-gray-900 dark:text-gray-300">{props.label}</label>
                    {/* <p id="helper-checkbox-text" className="text-xs font-normal text-gray-500 dark:text-gray-400">For orders shipped from $25 in books or $29 in other categories</p> */}
                </div>
            </div>
        </fieldset>

    )
}

export function SwitchInput() {
    return (
        <label className="inline-flex items-center mb-5 cursor-pointer">
            <input type="checkbox" value="" className="sr-only peer" checked/>
                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Toggle me</span>
        </label>
    )
}

export function SearchInput(){
    return (

<form className="max-w-md mx-auto">   
    <label  className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
    <div className="relative">
        <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
            </svg>
        </div>
        <input type="search" id="default-search" className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search Mockups, Logos..." required />
        <button type="submit" className="text-white absolute end-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Search</button>
    </div>
</form>

    )
}
import { Collection, Feature } from "ol";
import { Coordinate } from "ol/coordinate";
import { GeoJSON } from "ol/format";
import { Point } from "ol/geom";
import VectorLayer from "ol/layer/Vector";
import { fromLonLat } from "ol/proj";
import { Cluster } from "ol/source";
import VectorSource from "ol/source/Vector";
import { clusterStyleFunction, FeatureType, FeatureTypeKey, shapeStyleFunction } from "./styles";


export const DATA_PROJECTION = 'EPSG:4326', FEATURE_PROJECTION = 'EPSG:3857'


// const lineFeature = new Feature(new LineString([[-1e7, 1e6], [-1e6, 3e6],]));
// const polygonFeature = new Feature(new Polygon([[[-3e6, -1e6], [-3e6, 1e6], [-1e6, 1e6], [-1e6, -1e6], [-3e6, -1e6],],]));
// const features:Feature[] = [createIconPoint([1.373173901791856, 43.655812443196645], 'Test'), pointFeature]


export function NewPoint(id: string, center: Coordinate | undefined | null, properties: any, options?: { style?: any }) {
    const f = new Feature(center ? new Point(fromLonLat(center)) : undefined);
    f.setId(id)
    f.setProperties(properties)
    f.setStyle((options && options.style) ? options.style : shapeStyleFunction)
    return f
}

export function NewCollection(f: any[], properties?: any) {
    const c = new Collection(f);
    c.setProperties(properties)
    return c
}

export function NewFeaturesGeoJSON(f: any[]) {
    return new GeoJSON().readFeatures({
        "type": "FeatureCollection",
        "features": f
    }, {
        dataProjection: DATA_PROJECTION, featureProjection: FEATURE_PROJECTION
    })
}

export function NewVectorSource(features: any[] | Collection<any> | undefined) {
    return new VectorSource({
        features: features,
    })
}

export function NewClusterVectorLayer(source: VectorSource, options?: any) {
    const cluster = new Cluster({
        distance: options && options.distance ? options.distance : 10,
        minDistance: options && options.minDistance ? options.minDistance : 20,
        source: source,
    })

    return new VectorLayer({
        source: cluster,
        style: options && options.style ? options.style : clusterStyleFunction,
        visible: true
    })
}

export function NewSimpleVectorLayer(source: VectorSource, options?: any) {
    return new VectorLayer({
        source: source,
        style: options && options.style ? options.style : shapeStyleFunction,
        visible: true
    })
}

export function NewGeolocationPoint() {
    const p = NewPoint(FeatureType.HERE, undefined, { [`${FeatureTypeKey}`]: FeatureType.HERE })
    const vs = NewVectorSource([p])
    const vl = NewSimpleVectorLayer(vs)
    return { point: p, source: vs, layer: vl }
}
import classNames from "classnames"
import { useEffect, useState } from "react";

export function ProgressiveImage({src, placeholderSrc, width, height, ...props}: {src:string, width?:string|number, height?: string|number, placeholderSrc?:string, alt?:string, className?:string, style?:any}){
    const [imgSrc, setImgSrc] = useState(placeholderSrc || "/img/image_placeholder.svg" || src);
    
    const customClass =
    placeholderSrc && imgSrc === placeholderSrc ? "blur-sm" : "blur-none";

    useEffect(() => {
        const img = new Image();
        img.src = src;
        img.onload = () => {
          setImgSrc(src);
        };
      }, [src]);
    
      return (
        <img
          src={imgSrc}
          alt={props.alt || ""}
          width={width}
          height={height}
          style={{height: height, width:width}}
          className={classNames( "block bg-gray-200", customClass, props.className)}
        />
      );
}

export function Avatar(props: any){
    return <img {...props} className="w-10 h-10 rounded-full" src="/img/avatar_placeholder.svg" alt="Rounded avatar"/>
}


export function AvatarDotIndicator(){
    return <div className="relative">
    <img className="w-10 h-10 rounded-full" src="/docs/images/people/profile-picture-5.jpg" alt=""/>
    <span className="bottom-0 left-7 absolute  w-3.5 h-3.5 bg-green-400 border-2 border-white dark:border-gray-800 rounded-full"></span>
</div>
}

export function AvatarInitial(){
    return  <div className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
        <span className="font-medium text-gray-600 dark:text-gray-300">JL</span>
    </div>    
}

export function AvatarPlaceholder(){
    return     <div className="relative w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
        <svg className="absolute w-12 h-12 text-gray-400 -left-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd"></path></svg>
    </div>
    
    
}

export function StackedAvatars(){
    return <div className="flex -space-x-4 rtl:space-x-reverse">
    <img className="w-10 h-10 border-2 border-white rounded-full dark:border-gray-800" src="/docs/images/people/profile-picture-5.jpg" alt=""/>
    <img className="w-10 h-10 border-2 border-white rounded-full dark:border-gray-800" src="/docs/images/people/profile-picture-2.jpg" alt=""/>
    <img className="w-10 h-10 border-2 border-white rounded-full dark:border-gray-800" src="/docs/images/people/profile-picture-3.jpg" alt=""/>
    <a className="flex items-center justify-center w-10 h-10 text-xs font-medium text-white bg-gray-700 border-2 border-white rounded-full hover:bg-gray-600 dark:border-gray-800" href="#">+99</a>
</div>
}

export function AvatarText(){
    return (
        <div className="flex items-center gap-4">
        <img className="w-10 h-10 rounded-full" src="/docs/images/people/profile-picture-5.jpg" alt=""/>
        <div className="font-medium dark:text-white">
            <div>Jese Leos</div>
            <div className="text-sm text-gray-500 dark:text-gray-400">Joined in August 2014</div>
        </div>
    </div>
    )
    
}
import { Capacitor } from "@capacitor/core";
import { BellIcon, ChatBubbleLeftEllipsisIcon, MagnifyingGlassIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import { ButtonIcon } from "../../button";
import { useEffect, useState } from "react";
import { useUser } from "../../../hooks/useUser";
import { useLocation } from "react-router-dom";

export function NavBar(props: { backNavRef: any }) {
  const { isLogged } = useUser()
  const location = useLocation();
  const [logged, setLogged] = useState(false)
  useEffect(() => {
    // isLogged().then((u) => {
    //   console.log('[navbar] user already logged', u)
    //   setLogged(true)
    // }).catch(() => {
    //   setLogged(false)
    //   if (window.location.pathname != "/login" && window.location.pathname != "/register") {
    //     console.log('[navbar] user not logged')
    //     window.location.href = '/login'
    //     return
    //   }
      
    // })
    // TODO
    // Google Analytics
    // ga('send', 'pageview');
  }, [location])
  return <>
    <div className="sticky top-0 z-50 w-full h-14 flex flex-row justify-center bg-white  border-b border-gray-200">
      <div className="flex flex-row w-full items-center xl:w-full xl:max-w-[1800px] ">
        <div className="flex">
          <div className=" justify-center items-center align-middle  md:hidden hover:bg-gray-100 w-[80px] h-[55px]" ref={props.backNavRef}></div>

          {Capacitor.getPlatform() === 'web' && (
            <div className="flex hover:bg-gray-100 w-[55px] h-[55px] xl:w-[200px]">
              <img src="/img/furry_journey_transparent_white.png" height="55px" width="200px" className="hidden xl:block m-auto h-[55px] w-auto" />
              {/* <img src="/img/furry_journey_transparent_white.png" height={55} width={55} className="block xl:hidden m-auto" /> */}
            </div>

          )
          }
        </div>
        <div className="flex-grow" />
        {logged && <div className="flex align-middle justify-center items-center p-1 m-1">
          <ButtonIcon className={"ml-2"} href={"/notifications"}><BellIcon /></ButtonIcon>
          <ButtonIcon className={"ml-2"} href={"/messaging"}><ChatBubbleLeftEllipsisIcon /></ButtonIcon>
          <ButtonIcon className={"ml-2"} href={"/profile"}><UserCircleIcon /></ButtonIcon>
        </div>}
      </div>
    </div>

    {/* <StyledMobileMenu className={classNames('xl:hidden', menuOpen ? 'show' : 'hide')} */}
    {/* ref={sidebarRef}></StyledMobileMenu> */}
    {/* {sidebarRef.current && props.sidebar && createPortal(props.sidebar, sidebarRef.current)} */}
  </>
}
export function Title(props: any){
    return <h1 {...props} className="text-lg font-bold p-2"/>
}

export function TitleSkeleton(props: any){
    return <h1 {...props} className="text-lg font-bold p-2 animate-pulse"><div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-[40%] mb-4"></div></h1>
}

export function SubTitle(props: any){
    return <h1 {...props} className="font-bold p-2 text-gray-400"/>
}

export function SmallText(props: any){
    return <h1 {...props} className="text-gray-800 text-sm"/>
}


export function HR(){
    // <hr className="w-48 h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700">
    // 
    return <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>
}

export function HRIcon(){
    return <div className="inline-flex items-center justify-center w-full">
    <hr className="w-64 h-1 my-8 bg-gray-200 border-0 rounded dark:bg-gray-700"/>
    <div className="absolute px-4 -translate-x-1/2 bg-white left-1/2 dark:bg-gray-900">
        <svg className="w-4 h-4 text-gray-700 dark:text-gray-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 14">
    <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z"/>
  </svg>
    </div>
</div>
}

export function HRText(){
    return <div className="inline-flex items-center justify-center w-full">
    <hr className="w-64 h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"/>
    <span className="absolute px-3 font-medium text-gray-900 -translate-x-1/2 bg-white left-1/2 dark:text-white dark:bg-gray-900">or</span>
</div>
}
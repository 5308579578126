import React, { useEffect, useRef, useState } from 'react';
import { ProgressiveImage } from '../../../image';
import classNames from 'classnames';
const flvjs = require('./flv.min')

interface Props {
  poster?:string
  className?:string
  type?: string,
  url: string,
  isLive?: boolean,
  showControls?: boolean,
  hasAudio?: boolean,
  hasVideo?: boolean,
  enableStashBuffer?: boolean,
  stashInitialSize?: number,
  height?: string,
  width?: string,
  isMuted?: boolean,
  enableWarning?: boolean,
  enableError?: boolean,
  handleError?: (e:string)=>void
}


export function FlvPlayer({
  poster, 
  url, 
  type= 'flv',
  isLive= true,
  hasAudio= true,
  hasVideo= true,
  showControls= true,
  enableStashBuffer= true,
  stashInitialSize= 128,
  height= '100%',
  width= '100%',
  isMuted= false,
  handleError= (err)=>{console.log(err)},
  enableWarning= false,
  enableError= false,
  className= '',
}
: Props){
    const myRef = useRef<any>()
    let flvPlayer :any;
    const [isPlaying, setPlaying] = useState(false)

  useEffect(()=>{
    

    return ()=>{
      flvPlayer && flvPlayer.destroy();
      console.log('remove')
    }
  },[])

  useEffect(()=>{
    if(isPlaying && myRef){
      if (flvjs.isSupported()) {
        flvPlayer = flvjs.createPlayer({
          type,
          isLive,
          url,
          hasAudio,
          hasVideo
        },{
          enableStashBuffer,
          stashInitialSize
        });
  
  
        flvjs.LoggingControl.enableError = false;
        flvjs.LoggingControl.enableWarn = enableWarning;
  
  
        flvPlayer.attachMediaElement(myRef.current); 
        flvPlayer.load()
        flvPlayer.play()
        flvPlayer.on('error', (err:string)=>{
          console.error('flv player error:', err);
          handleError(err);
        });
      }

    }
  }, [isPlaying])
  return <div  className={classNames(className, "cursor-pointer rounded  h-auto max-w-full")} onClick={()=>{

  }}>

    {!isPlaying && <div onClick={()=>setPlaying(true)}><img src={poster ?? "/img/video_vignette.png"}  className="h-auto max-w-full"/></div>}
    {isPlaying && <video controls={showControls} muted={false} ref={myRef} style={{height, width}} className="h-auto max-w-full" poster={poster ?? "/img/video_vignette.png"}/>}
  </div>
}


import { HTMLAttributes, HtmlHTMLAttributes, PropsWithChildren } from "react";
import { ProgressiveImage } from ".";
import { FlvPlayer } from "../video/flv/wrapper/ReactFlvPlayer";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import { Button, ButtonOutline } from "../button";
import { useViewPort } from "../../mobile/viewport";

interface GridProps extends PropsWithChildren<HTMLAttributes<HTMLElement>> {

}

export function GridSkeleton({length = 10, className}: {length?: number, className?:string}) {
    return (
        <div className={classNames("grid grid-cols-1 md:grid-cols-3 gap-4 px-1", className)}>
           {Array.from({length}, (_, i) => <GridCardSkeleton/>)}
        </div>

    )
}




export function GridCardSkeleton(props: any) {
    return <NavLink to={props.href ?? ''}>
        <figure className="relative block transition-all  duration-300 cursor-pointer overflow-hidden h-[200px]" >
            <ProgressiveImage className="rounded-lg h-[200px]" alt="image description" {...props} />
        </figure>
    </NavLink>
}

//  END SKELETON

export function Grid(props: GridProps) {
    return (
        <div className={classNames("grid grid-cols-1 md:grid-cols-3 gap-4 px-1", props.className)}>
            {props.children}
        </div>

    )
}

interface GridItemProps {
    key?: string, 
    img: string, 
    badge: string, 
    width?:string|number, 
    height?:string|number, 
    href?:string, 
    target?:string, 
    alt?: string
}

export function MapPopupCard(props: GridItemProps& {line1: string, line2:string, line3:string, onClick?:any}) {
    const {isSM} = useViewPort()

    return <div className="absolute p-2 rounded-lg bg-white w-[215px]">
        <figure className="relative block transition-all   cursor-pointer overflow-hidden h-[112px] w-[200px]" style={{height: props.height, width:props.width}}>
            <ProgressiveImage className="rounded-lg   h-[112px] w-[200px]" src={props.img} alt="image description" {...props} height={props.height} width={props.width}/>
            <figcaption className="absolute px-4 md:text-lg text-white top-1 bg-white rounded-r  border-y border-r border-gray-400">
                <p className="text-gray-900 font-bold">{props.badge}</p>
            </figcaption>
        </figure>
        <div className="flex flex-col">
            <p className="text-gray-900 font-bold truncate">{props.line1}</p>
            <p className="text-gray-900 text-sm">{props.line2}</p>
            {/* <p className="text-gray-900 text-sm truncate">{props.line3}</p> */}
            <ButtonOutline href={props.href ?? ''} target={isSM? "" : "_blank"}>Voir</ButtonOutline>
            {/* <ButtonOutline onClick={() => props.onClick()}>Détail</ButtonOutline> */}
        </div>
    </div>
}
export function GridCard(props: GridItemProps) {
    return <NavLink key={props.key}  to={props.href ?? ''}>
        <figure className="relative block transition-all  duration-300 cursor-pointer overflow-hidden h-[200px]" style={{height: props.height, width:props.width}}>
            <ProgressiveImage className="rounded-lg h-[200px]" src={props.img} alt="image description" {...props} height={props.height} width={props.width}/>
            <figcaption className="absolute px-4 md:text-lg text-white top-1 bg-white rounded-r ">
                <p className="text-gray-900 font-bold ">{props.badge}</p>
            </figcaption>
        </figure>
    </NavLink>
}

export function GridCardText(props: GridItemProps& {line1: string, line2:string, line3:string}) {
    return <NavLink key={props.key} to={props.href ?? ''} target={props.target ?? ''} className="p-2 rounded-lg hover:bg-gray-100">
        <figure className="relative block transition-all  duration-300 cursor-pointer overflow-hidden h-[200px]" style={{height: props.height, width:props.width}}>
            <ProgressiveImage className="rounded-lg  h-[200px]" src={props.img} alt="image description" {...props} height={props.height} width={props.width}/>
            <figcaption className="absolute px-4 md:text-lg text-white top-1 bg-white rounded-r  border-y border-r border-gray-400">
                <p className="text-gray-900 font-bold">{props.badge}</p>
            </figcaption>
        </figure>
        <div className="flex flex-col">
            <p className="text-gray-900">{props.line1}</p>
            <p className="text-gray-900 text-sm">{props.line2}</p>
            <p className="text-gray-900 text-sm">{props.line3}</p>
        </div>
    </NavLink>
}
export function GridVideoCard(props: { img:string, src: string, label: string, alt?: string }) {
    return <div>
        <figure className="relative inline-block transition-all  duration-300 cursor-pointer filter grayscale-0 hover:grayscale">
            <a href="#">
                <FlvPlayer
                url={props.src}
                poster={props.img}
                className={"h-auto max-w-full"} 
                />
            </a>
            <figcaption className="absolute px-4 md:text-lg text-white top-1 bg-white rounded-r">
                <p className="text-gray-900 font-bold">{props.label}</p>
            </figcaption>
        </figure>
    </div>
}
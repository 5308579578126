import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AsideFragment } from "../../layouts/fragments/aside";
import { BackNavLink } from "../../layouts/fragments/backnavlink";
import { Content, Page } from "../../layouts/frame";
import { GetEvent } from "../../lib/api";
import { Event } from "../../lib/api/types";
import { Section, StyledPage } from "../../lib/components/content/block";
import { ProgressiveImage } from "../../lib/components/image";
import { SubTitle, Title } from "../../lib/components/typo";


export function EventDetail(){
    const {id} = useParams()
    const [event, setEvent] = useState<Event>()
    const [related, setRelatedEvents] = useState<Event[]>()
    
    useEffect(()=>{
        if (id){
            GetEvent({id}).then(setEvent)
            // GetRelatedEvents({id}).then(setRelatedEvents)
        }
    }, [id])

    console.log('event', event)
    return <Page>
            <BackNavLink to="/events"/>

            {
                event && 
                <Content>
                    <Section className="pt-4 pb-4">
                            <Title>{event.event_title}</Title>
                            <div className="flex flex-col md:flex-row items-center p-2">
                            {/* <BadgeLegendIndicator
                                className="bg-blue-50 border-blue-400 text-blue-600">{event.event_category_name}</BadgeIndicator> */}
                            <div className={"grow"}/>

                            <div
                                className="block text-slate-400 font-semibold text-sm">{event.event_begin_at}</div>
                            {event.event_end_at && <div className="pl-2 block flex flex-row text-slate-400 font-semibold text-sm">
                                <div className="pr-1">au</div>
                                {event.event_end_at}</div>}
                            </div>
                            {/* <SDivider/> */}
                            <div className="flex justify-center">
                                <ProgressiveImage src={event.event_media_url} alt=""/>
                            {/* <Image fill={true} style={{objectFit: "cover"}}
                                    src={event.event_media_url || "/images/logo.png"}
                                    alt={event.event_title}/> */}
                            </div>
                            <div className="italic p-4 text-justify">{event.event_summary}</div>
                        </Section>
                        <Section className="pt-4 pb-4">
                            <StyledPage html={event.event_content + ''}/>
                        </Section>


                        
                        <Section>
                            <SubTitle>Commentaires</SubTitle>
                            {/* <CommentPanel objectType={"events"}
                                        objectId={event.event_id}
                                        account={account}/> */}
                        </Section>
                </Content>
            }
            
            <AsideFragment>
                    <Section className="pt-4 pb-4">
                            <SubTitle>Recommandé pour vous</SubTitle>
                            {/* <SDivider/> */}
                            <div className="grid grid-cols-1 gap-2">
                            {/* {related && related.map(p => <Article key={'e-' + p.event_id}
                                                                    title={p.event_title}
                                                                    date={p.event_begin_at}
                                                                    description={''}
                                                                    link={"/events/" + p.event_slug + "/" + p.event_id}
                                                                    badges={[{name: p.event_category_name}]}
                                                                    img={p.event_media_url}/>)} */}
                            </div>
                        </Section>
                </AsideFragment>
    </Page>
}
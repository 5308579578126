import { NavLink, useOutletContext } from "react-router-dom";
import { eventBus, Page } from "../frame";
import { GlobeEuropeAfricaIcon, HomeIcon, PlusIcon, FireIcon, MapPinIcon, HomeModernIcon, CalendarIcon, NewspaperIcon, MegaphoneIcon, TvIcon, UserGroupIcon, ShoppingBagIcon, Bars3Icon, EllipsisHorizontalCircleIcon } from '@heroicons/react/24/outline'
import { MenuItem, MenuLink } from "../menu";
import { Title } from "../../lib/components/typo";
import { createPortal } from "react-dom";
import { Fragment } from "react/jsx-runtime";
import { Button } from "../../lib/components/button";


  
export function ScreenOverlayFragment({title, children}: any){
  const {screenOverlayRef} = useOutletContext<any>();

  return  (
    (screenOverlayRef && createPortal(<Fragment>
              <div className="h-[55px] flex border-b">
            <div className="flex flex-1 items-center justify-items-center p-2">
              <Title>{title}</Title>
            </div>
            <div className="flex items-center justify-items-center p-2" onClick={()=> eventBus.next('screen-overlay:close')}>
              Fermer
            </div>
        </div>
        <div>{children}</div></Fragment>, screenOverlayRef))
  )
}

export function ScreenOverlayAction({children}: any){
  
  const {setScreenOverlayVisible} = useOutletContext<any>();
  return <div onClick={()=>{
                setScreenOverlayVisible(true)
            }}>{children}</div>
}
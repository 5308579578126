import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CommentFragment } from "../../../layouts/fragments/comment";
import { Page } from "../../../layouts/frame";
import { GetGroup } from "../../../lib/api";
import { Section } from "../../../lib/components/content/block";
import { Title } from "../../../lib/components/typo";
import { Grid, GridCard } from "../../../lib/components/image/gridimage";

export default function GroupMediaPage() {
  const [group, setGroup] = useState<any>()
  const {id} = useParams()
  useEffect(() => {
    if(id){
      GetGroup({id}).then(setGroup)
    }
  }, [id])

  if(!group){
    return <Page>Chargement...</Page>
  }

  return (
    <>
        <Title>Galerie photo</Title>
        <Section>
        <Grid>
              <GridCard  img="https://static.lespetitespatounes.com/events/5a555d29-b79b-4476-8f2f-9e16574b87c1/pictures/8e5d85ce-e46d-11ee-80ee-4e7db3d247d3.jpg"   badge="Festivals"/>
              <GridCard  img="https://static.lespetitespatounes.com/events/d9697aa2-5725-41fa-af72-1663f7795a73/pictures/93422ee4-e46f-11ee-a14f-4e7db3d247d3.jpg" badge="Activités sportives" />
              <GridCard  img="https://static.lespetitespatounes.com/events/802f2df4-d4dd-400f-85dc-c681367d8565/pictures/1c8e9b16-e46f-11ee-b00c-4e7db3d247d3.jpg" badge="Salons et Expositions" />
              <GridCard  img="https://static.lespetitespatounes.com/events/5a555d29-b79b-4476-8f2f-9e16574b87c1/pictures/8e5d85ce-e46d-11ee-80ee-4e7db3d247d3.jpg"   badge="Festivals"/>
              <GridCard  img="https://static.lespetitespatounes.com/events/d9697aa2-5725-41fa-af72-1663f7795a73/pictures/93422ee4-e46f-11ee-a14f-4e7db3d247d3.jpg" badge="Activités sportives" />
              <GridCard  img="https://static.lespetitespatounes.com/events/802f2df4-d4dd-400f-85dc-c681367d8565/pictures/1c8e9b16-e46f-11ee-b00c-4e7db3d247d3.jpg" badge="Salons et Expositions" />
              <GridCard  img="https://static.lespetitespatounes.com/events/5a555d29-b79b-4476-8f2f-9e16574b87c1/pictures/8e5d85ce-e46d-11ee-80ee-4e7db3d247d3.jpg"   badge="Festivals"/>
              <GridCard  img="https://static.lespetitespatounes.com/events/d9697aa2-5725-41fa-af72-1663f7795a73/pictures/93422ee4-e46f-11ee-a14f-4e7db3d247d3.jpg" badge="Activités sportives" />
              <GridCard  img="https://static.lespetitespatounes.com/events/802f2df4-d4dd-400f-85dc-c681367d8565/pictures/1c8e9b16-e46f-11ee-b00c-4e7db3d247d3.jpg" badge="Salons et Expositions" />
              <GridCard  img="https://static.lespetitespatounes.com/events/5a555d29-b79b-4476-8f2f-9e16574b87c1/pictures/8e5d85ce-e46d-11ee-80ee-4e7db3d247d3.jpg"   badge="Festivals"/>
              <GridCard  img="https://static.lespetitespatounes.com/events/d9697aa2-5725-41fa-af72-1663f7795a73/pictures/93422ee4-e46f-11ee-a14f-4e7db3d247d3.jpg" badge="Activités sportives" />
              <GridCard  img="https://static.lespetitespatounes.com/events/802f2df4-d4dd-400f-85dc-c681367d8565/pictures/1c8e9b16-e46f-11ee-b00c-4e7db3d247d3.jpg" badge="Salons et Expositions" />
              <GridCard  img="https://static.lespetitespatounes.com/events/5a555d29-b79b-4476-8f2f-9e16574b87c1/pictures/8e5d85ce-e46d-11ee-80ee-4e7db3d247d3.jpg"   badge="Festivals"/>
              <GridCard  img="https://static.lespetitespatounes.com/events/d9697aa2-5725-41fa-af72-1663f7795a73/pictures/93422ee4-e46f-11ee-a14f-4e7db3d247d3.jpg" badge="Activités sportives" />
              <GridCard  img="https://static.lespetitespatounes.com/events/802f2df4-d4dd-400f-85dc-c681367d8565/pictures/1c8e9b16-e46f-11ee-b00c-4e7db3d247d3.jpg" badge="Salons et Expositions" />
          
                </Grid>
        </Section>

    </>
  )
} 
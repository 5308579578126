import axios from 'axios';
import { setupCache } from 'axios-cache-interceptor';
import { Capacitor, CapacitorHttp } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';

export interface Data<T> {
  error?: {
    code: number,
    message: string,
  }
  data?: T
}

const base = "/api/v1/web" //
const getHeaders = async () => {
  const p = await Preferences.get({ key: 'current_user' })
  let u = undefined
  if (p.value) {
    u = JSON.parse(p.value)
  }

  return {
    'Authorization': u && u.token ? u.token : ''
  }
}


export async function get<T>(input: RequestInfo, rejectValue?: T): Promise<T> {
  return await CapacitorHttp.get({
    url: `${base}${input}`,
    headers: await getHeaders(),
  }).then((r: any) => {
    console.debug(`[${r.status}] GET  ${input}`)
    return r.data
  }).catch(e => {
    console.error(`GET ${input}`, e)
    return rejectValue
  })
}

export async function post<T>(input: RequestInfo, body: any, rejectValue?: T): Promise<T> {

  return await CapacitorHttp.post({
    url: `${base}${input}`,
    data: body,
    headers: await getHeaders(),
  }).then((r: any) => {
    console.debug(`[${r.status}] GET  ${input}`)
    return r.data
  }).catch(e => {
    console.error(`GET ${input}`, e)
    return rejectValue
  })
}

export async function hasAuthentication(): Promise<boolean> {
  return false // cookies().get("sid_lppn") ? Promise.resolve(true) : Promise.reject(false);
}




// Generic function to throw if any errors occurred, or return the responses
// if no errors happened
function handleResults(results: any) {
  const errors = results
    .filter((result: any) => result.status === 'rejected')
    .map((result: any) => result.reason)

  if (errors.length) {
    // Aggregate all errors into one
    throw new AggregateError(errors)
  }

  return results.map((result: any) => result.value)
}
import CircleStyle from "ol/style/Circle";
import { Fill, Icon, Stroke, Style, Text } from "ol/style";
import { StyleLike } from "ol/style/Style";
import { FlatStyleLike } from "ol/style/flat";
import { FeatureLike } from "ol/Feature";
import { Capacitor } from "@capacitor/core";


export const FeatureTypeKey = "display_type"
export enum FeatureType {
  HERE = "here",
  ERROR = "error",
  // 
  LODGING = "lodging",
  PLACE = "places",
}


function NewIconSyle(src: string): Icon {
  const iconSize = (Capacitor.getPlatform() === 'ios' || Capacitor.getPlatform() === 'android') ? 48 : 36
  return new Icon({
    // size: [iconSize, iconSize], 
    scale: iconSize/48,  // orignal image is 48px => scale to 32px (32/48 = 0.66)
    anchor: [0, 0], 
    anchorXUnits: 'fraction', 
    anchorYUnits: 'pixels', 
    src: src,
  })
}
// IMAGES

const circleErrorImage = new CircleStyle({
  radius: 10, stroke: new Stroke({ color: '#fff', width: 3 }), fill: new Fill({ color: 'red' })
});
const circleImage = new CircleStyle({
  radius: 5, stroke: new Stroke({ color: '#fff', width: 1 }), fill: new Fill({ color: 'green' })
});
const bagDispenserImage = new Icon({
  size: [48, 48], scale: 1,  // orignal image is 48px => scale to 32px (32/48 = 0.66)
  anchor: [0, 0], anchorXUnits: 'fraction', anchorYUnits: 'pixels', src: '/img/map/6.png',
})
const dogToiletImage = new Icon({
  size: [48, 48], scale: 1,  // orignal image is 48px => scale to 32px (32/48 = 0.66)
  anchor: [0, 0], anchorXUnits: 'fraction', anchorYUnits: 'pixels', src: '/img/map/5.png',
})

const waterFountainImage = new Icon({
  size: [48, 48], scale: 1,  // orignal image is 48px => scale to 32px (32/48 = 0.66)
  anchor: [0, 0], anchorXUnits: 'fraction', anchorYUnits: 'pixels', src: '/img/map/8.png',
})

const dogParkImage = new Icon({
  size: [48, 48], scale: 1,  // orignal image is 48px => scale to 32px (32/48 = 0.66)
  anchor: [0, 0], anchorXUnits: 'fraction', anchorYUnits: 'pixels', src: '/img/map/3.png',
})
const hereImage = new Icon({
  size: [48, 48], scale: 0.7,  // orignal image is 48px => scale to 32px (32/48 = 0.66)
  anchor: [0, 0], anchorXUnits: 'fraction', anchorYUnits: 'pixels', src: '/img/map/1.png',
})
const lodgingImage = new Icon({
  size: [48, 48], scale: 0.7,  // orignal image is 48px => scale to 32px (32/48 = 0.66)
  anchor: [0, 0], anchorXUnits: 'fraction', anchorYUnits: 'pixels', src: '/img/map/a.png',
})

// TYPE STYLES
const typeStyleCache: any = {
  'bag_dispenser': new Style({
    image: bagDispenserImage
  }),
  'dog_toilet': new Style({
    image: dogToiletImage
  }),
  'water_fountain': new Style({
    image: waterFountainImage
  }),
  'dog_park': new Style({
    image: dogParkImage
  }),
  [`${FeatureType.HERE}`]: new Style({
    image: hereImage
  }),
  [`${FeatureType.ERROR}`]: new Style({
    image: circleImage
  }),
  [`${FeatureType.LODGING}`]: new Style({
    image: NewIconSyle("/img/map/lodging.png")
  }),
  [`${FeatureType.LODGING}_active`]: new Style({
    image: NewIconSyle("/img/map/lodging_active.png")
  }),
  [`${FeatureType.PLACE}`]: new Style({
    image: NewIconSyle("/img/map/3.png")
  }),
  [`${FeatureType.PLACE}_active`]: new Style({
    image: NewIconSyle("/img/map/3.png")
  }),
};

function createTypeStyle(type: string, active?: boolean) {
  return typeStyleCache[type + (active ? '_active' : '')] ?? typeStyleCache[`${FeatureType.ERROR}`]
}

function getTypeStyle(type: string | undefined, active?: boolean) {
  if (!type) {
    console.warn('failed to get feature geosjon for type:', type)
    return typeStyleCache[`${FeatureType.ERROR}`]
  }
  return createTypeStyle(type, active);
}

const clusterStyleFunction: StyleLike | FlatStyleLike = function (feature: FeatureLike): any {
  const size = feature.get('features').length;
  if (size > 1) {
    let style = styleCache[size];
    if (!style) {
      style = new Style({
        image: new CircleStyle({
          radius: 10,
          stroke: new Stroke({
            color: '#fff',
          }),
          fill: new Fill({
            color: '#3399CC',
          }),
        }),
        text: new Text({
          text: size.toString(),
          fill: new Fill({
            color: '#fff',
          }),
        }),
      });
      styleCache[size] = style;
    }
    return style
  } else {
    return getTypeStyle(feature.get('features')[0].get(FeatureTypeKey), feature.get('features')[0].get("selected") == "selected");
  }
};


// SHAPE STYLES
const styleCache: any = {};
const shapeStyleFunction: StyleLike | FlatStyleLike = function (feature: FeatureLike): any {
  const geo = feature.getGeometry()
  if (!geo) {
    return Styles['PointError']
  }

  return getTypeStyle(feature.get(FeatureTypeKey), feature.get("selected") == "selected")
};


// -----------------------

const Styles: any = {
  'PointError': new Style({
    image: circleErrorImage,
  }),

  'Point': new Style({
    image: circleImage,
  }), 'LineString': new Style({
    stroke: new Stroke({
      color: 'green', width: 1,
    }),
  }), 'MultiLineString': new Style({
    stroke: new Stroke({
      color: 'green', width: 1,
    }),
  }), 'MultiPoint': new Style({
    image: circleImage,
  }), 'MultiPolygon': new Style({
    stroke: new Stroke({
      color: 'yellow', width: 1,
    }), fill: new Fill({
      color: 'rgba(255, 255, 0, 0.1)',
    }),
  }), 'Polygon': new Style({
    stroke: new Stroke({
      color: 'blue', lineDash: [4], width: 3,
    }), fill: new Fill({
      color: 'rgba(0, 0, 255, 0.08)',
    }),
  }), 'GeometryCollection': new Style({
    stroke: new Stroke({
      color: 'magenta', width: 2,
    }), fill: new Fill({
      color: 'magenta',
    }), image: new CircleStyle({
      radius: 10, // fill: null,
      stroke: new Stroke({
        color: 'magenta',
      }),
    }),
  }), 'Circle': new Style({
    stroke: new Stroke({
      color: 'red', width: 2,
    }), fill: new Fill({
      color: 'rgba(255,0,0,0.2)',
    }),
  }),
};

export {
  Styles,
  clusterStyleFunction,
  shapeStyleFunction
}

import { Fragment, useEffect, useState } from "react";
import { AsideFragment } from "../../layouts/fragments/aside";
import { ScreenOverlayAction } from "../../layouts/fragments/create";
import { DynamicFragment, FragmentType } from "../../layouts/fragments/hydratror";
import { SearchFragment } from "../../layouts/fragments/search";
import { Content, Page } from "../../layouts/frame";
import { GetEventCategories, GetEventFilters, GetEvents } from "../../lib/api";
import { Button } from "../../lib/components/button";
import { Section } from "../../lib/components/content/block";
import { Grid, GridSkeleton } from "../../lib/components/image/gridimage";
import { Title, TitleSkeleton } from "../../lib/components/typo";
import { CreateEventFragment } from "./fragments/create";

export default function EventsPage() {
  const [sections, setSections] = useState<any>()
  const [categories, setCategories] = useState<any>()
  const [filters, setFilters] = useState<any>()

  useEffect(() => {
    GetEvents().then(setSections)
    GetEventCategories().then(setCategories)
    GetEventFilters().then(setFilters)
  }, [])


  return (
    <Page>
      <Content>
        {filters && <SearchFragment placeholder={filters.placeholder} filterElement={() => <Fragment>
          {filters.filters.map((f: any) => <div>
            {f.label}
            <input placeholder={f.type} />
          </div>)}
        </Fragment>} />}

        {!sections && Array.from({ length: 5 }, _ => <Section><TitleSkeleton /><GridSkeleton /></Section>)}

        {sections && sections.sections.map((section: any) => (
          <Section key={section.title} >
            <Title>{section.title}</Title>

            <Grid>
              {section.items.map((s: any) => {
                return DynamicFragment(FragmentType.CardElement, {
                  key: section.title + s.id,
                  img: s.medias_url,
                  badge: s.category_name,
                  href: '/events/' + s.id,
                  line1: s.title,
                  line2: s.begin_at,
                  line3: `${s.location.online ? "En ligne, " : "" } `+ s.location.department.name + ', ' + s.location.city.name
                })
              })}
            </Grid>
          </Section>
        ))
        }

        {categories && categories.sections.map((section: any) => <Section key={section.title}>
          <Title>{section.title}</Title>

          <div className="flex flex-col md:flex-row bg-gray-200 p-3 rounded-lg">
            {section.items.map((s: any) => {
              return DynamicFragment(FragmentType.Badge, {
                className: "bg-white block w-full text-center my-2 md:mx-2 md:px-3 md:w-fit",
                key: section.title + s.id,
                href: '/events?category=' + s.id,
                label: s.title,
              })
            })}
          </div>
        </Section>
        )
        }

      </Content>

      <AsideFragment>
        <Section>
          <ScreenOverlayAction>
            <Button>Publier un évènement</Button>
          </ScreenOverlayAction>
        </Section>
      </AsideFragment>

      <CreateEventFragment />
    </Page>

  )
} 
import { NavLink, useNavigate } from "react-router-dom";

export default function Tab({ items }: { items: { label: string, href: string }[] }) {
    const navigate  = useNavigate()
    
    return (
        <>
            <div className="sm:hidden">
                <label className="sr-only">Select your country</label>
                <select id="tabs" onChange={(e) => {
                    console.log('change:',e.target.value)
                    navigate(e.target.value)
                    
                }} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                    {items.map((i: any) => <option value={i.href}>{i.label}</option>)}
                </select>
            </div>
            <ul className="hidden text-sm font-medium text-center text-gray-500 rounded-lg  border-gray-200 border sm:flex dark:divide-gray-700 dark:text-gray-400">
                {items.map((i: any) => <li key={i.label} className="w-full focus-within:z-10">
                    <NavLink to={i.href} className="inline-block w-full p-2 bg-white border-r border-gray-200 dark:border-gray-700 hover:text-[#1476dd] hover:bg-gray-50 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700">{i.label}</NavLink>
                </li>)}
            </ul>
        </>
    )
}
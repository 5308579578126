import { AnimatePresence } from 'framer-motion'
import { useNavigate } from 'react-router-dom'

/**
 * A react-route Switch enhanced with AnimatePresence to trigger animations between routes
 */
export default function AnimatedSwitch({ children }) {
  const history = useNavigate()

  return (
    <AnimatePresence
      exitBeforeEnter={false}
      initial={false}
      // the exit animation needs the updated history to know if it's a push or pop
      custom={{ action: history.action }}
    >
     {children}
    </AnimatePresence>
  )
}

import { createPortal } from "react-dom";
import { useOutletContext } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";


export function AsideFragment({children}: any){
    const {asideFilter} = useOutletContext<any>();

    return  (
      // (asideFilter && createPortal(<div className="grid max-w-xs grid-cols-3 gap-1 p-1 mx-auto my-2">{children}</div>, asideFilter))
      (asideFilter && createPortal(<div className="flex  flex-col justify-center p-2">{children}</div>, asideFilter))
    )
}


import { AsideFragment } from "../../layouts/fragments/aside";
import { ScreenOverlayAction, ScreenOverlayFragment } from "../../layouts/fragments/create";
import { Content, Page } from "../../layouts/frame";
import { Button } from "../../lib/components/button";
import { Section } from "../../lib/components/content/block";
import { Grid, GridVideoCard } from "../../lib/components/image/gridimage";
import { SubTitle, Title } from "../../lib/components/typo";

export default function PlayerPage() {
  
  return <Page>
  <Content>
  
  <Section className="flex justify-center">
    <GridVideoCard src="https://livestream.lespetitespatounes.com/live/livestream.flv" 
                   img="https://static.lespetitespatounes.com/events/6c0eb063-8114-4be3-9f3d-fd150de2353e/pictures/1e5372e2-e46e-11ee-973f-4e7db3d247d3.jpg"   
                   label="En direct"/>
  </Section>

  <Section>
    <Title>Evenements</Title>
    <SubTitle>Genres</SubTitle>
    <Grid>
    <GridVideoCard src="https://livestream.lespetitespatounes.com/live/livestream.flv"  img="https://static.lespetitespatounes.com/events/5a555d29-b79b-4476-8f2f-9e16574b87c1/pictures/8e5d85ce-e46d-11ee-80ee-4e7db3d247d3.jpg"   label="Festivals"/>
    <GridVideoCard src="https://livestream.lespetitespatounes.com/live/livestream.flv"  img="https://static.lespetitespatounes.com/events/d9697aa2-5725-41fa-af72-1663f7795a73/pictures/93422ee4-e46f-11ee-a14f-4e7db3d247d3.jpg" label="Activités sportives" />
    <GridVideoCard src="https://livestream.lespetitespatounes.com/live/livestream.flv"  img="https://static.lespetitespatounes.com/events/802f2df4-d4dd-400f-85dc-c681367d8565/pictures/1c8e9b16-e46f-11ee-b00c-4e7db3d247d3.jpg" label="Salons et Expositions" />
    </Grid>

    <SubTitle>Espèces</SubTitle>
    <Grid>
    <GridVideoCard src="https://livestream.lespetitespatounes.com/live/livestream.flv"  img="https://flowbite.s3.amazonaws.com/docs/gallery/square/image-3.jpg" label="Chat"/>
    <GridVideoCard src="https://livestream.lespetitespatounes.com/live/livestream.flv"  img="https://flowbite.s3.amazonaws.com/docs/gallery/square/image-4.jpg" label="Chien"/>
    <GridVideoCard src="https://livestream.lespetitespatounes.com/live/livestream.flv"  img="https://flowbite.s3.amazonaws.com/docs/gallery/square/image-5.jpg" label="Lapin"/>
    </Grid>
  </Section>
    
  </Content>


  <AsideFragment>
    <Section>
      <ScreenOverlayAction>
        <Button>Publier une vidéo</Button>
      </ScreenOverlayAction>
    </Section>
  </AsideFragment>

  <ScreenOverlayFragment title='Publier sur une vidéo'>

    <Section>
      <div className="border p-3 text-blue-700">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Emplacement</label>
        (O) Position courante
      </div>
    </Section>

    <Section>
      <div className="border p-3 ">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Catégorie</label>
        (O) Sortie locale
      </div>
    </Section>

    <Section>
      <div className="border p-3 ">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Ajouter une vidéo</label>
        (O) Cliquez ici
      </div>
    </Section>


    <Section>
      <Button>Publier</Button>
    </Section>
  </ScreenOverlayFragment>
</Page>

  
} 
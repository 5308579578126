import classNames from "classnames";
import { NavLink } from "react-router-dom";

export function BadgeIndicator({className = 'bg-green-500'}: {className: string}){
    return <span className={classNames("flex w-3 h-3 me-3 rounded-full", className)}></span>
}

export function BadgeLegendIndicator({label, className = 'bg-green-500'}: {label: string, className: string}){
    return <span className="flex items-center text-sm font-medium text-gray-900 dark:text-white me-3">
        <span className={classNames("flex w-2.5 h-2.5 rounded-full me-1.5 flex-shrink-0", className)}></span>{label}</span>
}

export function Badge({label, href, ...props}: {color: string, label: string, href: string, className?: string}){
    return <NavLink to={href}><span className={classNames(`inline-block border border-gray-300 rounded-lg py-1 px-2 dark:bg-gray-900 dark:text-gray-300`, props.className)}>{label}</span></NavLink>
}
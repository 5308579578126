import classNames from "classnames"
import { Fragment, HTMLAttributes, PropsWithChildren, Suspense, useEffect, useRef, useState } from "react"
import { Navigate, Outlet, useLocation, useNavigate, useOutletContext } from "react-router-dom"
import { Subject } from "rxjs"
import AnimatedSwitch from "../lib/AnimatedSwitch"
import Spinner from "../lib/components/loader/spinner"
import { NavBar } from "../lib/components/nav"
import { useViewPort } from "../lib/mobile/viewport"
import { MenuFragment } from "./menu"
import AnimatedPage from "./Page"
import { useUser } from "../lib/hooks/useUser"

export const eventBus = new Subject();

export default function Frame({ props, children }: any) {

  const [toolbarRef, setToolbarRef] = useState<any>(null);
  const [bottomFilterRef, setBottomFilterRef] = useState<any>(null);
  const [topFilterRef, setTopFilterRef] = useState<any>(null);
  const [asideFilter, setAsideFilter] = useState<any>(null);
  const mainRef = useRef(null)
  const [screenOverlayRef, setScreenOverlayRef] = useState<any>(null)
  const [backNavRef, setBackNavRef] = useState<any>(null)
  const [screenOverlayVisible, setScreenOverlayVisible] = useState<boolean | string>(false)


  useEffect(() => {
    const subscription = eventBus.subscribe((e) => {
      switch (e) {
        case 'screen-overlay:open':
          setScreenOverlayVisible(true);
          return
        case 'screen-overlay:close':
          setScreenOverlayVisible(false);
      }
    });
    return () => subscription.unsubscribe();

  }, [])


  return (
    <div className="h-screen overflow-hidden flex flex-col items-center ">
      <NavBar backNavRef={setBackNavRef} />

      <div className="flex-grow flex flex-col md:flex-row w-full overflow-auto xl:max-w-[1800px]">
        <menu className="hidden xl:flex xl:flex-row xl:w-[300px] ">
          <div className="overflow-y-auto w-[300px] h-[calc(100vh-56px)]">
            <MenuFragment toolbar={toolbarRef} />
          </div>
        </menu>
        <main className="flex flex-col  flex-1 overflow-auto h-[calc(100vh-56px)]" ref={mainRef}>
          <div className="h-full overflow-auto">
            <div className="" ref={current => setTopFilterRef(current)}></div>
            <div className="pb-[60px] md:pb-0">
              <AnimatedSwitch>
                {children ?? <Outlet context={{ bottomFilterRef, topFilterRef, asideFilter, mainRef, screenOverlayRef, backNavRef, setScreenOverlayVisible }} />}
              </AnimatedSwitch>
            </div>

          </div>
        </main>
        <aside className="hidden xl:flex xl:flex-row xl:w-[350px] ">
          <div className="overflow-y-auto fixed  h-screen w-[350px] ">

            <div className="" ref={current => setAsideFilter(current)}></div>
          </div>
        </aside>
      </div>

      <div className="fixed bottom-0 z-50 w-full bg-white border">
        <div className="w-full">
          <div className="" ref={current => setBottomFilterRef(current)} />
        </div>
        <div ref={current => setToolbarRef(current)}></div>
      </div>

      {screenOverlayVisible && <div className="fixed h-screen top-0 z-50 w-full bg-white">
        <div ref={ref => setScreenOverlayRef(ref)}></div>
      </div>}
    </div>

  )
}


export const Page = (props: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) => {
  const vp = useViewPort()
  return vp.isSM ? <AnimatedPage title={"hiha"}><Fragment  {...props} /></AnimatedPage> : <Fragment  {...props} /> 
}

export const Content = ({ children, ...rest }: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) => {
  return <div {...rest} className={classNames('flex flex-col w-full overflow-hidden p-2')}>
    <Suspense fallback={<Spinner />}>
      {/* <ReactPullToRefresh onRefresh={() => {
        window.location.reload()
        return new Promise(resolve => setTimeout(resolve, 5000))
      }}> */}

      {children}

      {/* </ReactPullToRefresh> */}
    </Suspense>
  </div>
}

export const Aside = ({ children, ...rest }: { mobile?: boolean } & PropsWithChildren<HTMLAttributes<HTMLDivElement>>) => {
  return <div {...rest} className={classNames("p-2 overflow-hidden md:w-6/12 lg:w-4/12 xl:w-4/12", { "hidden md:block": rest.mobile == false })}>
    {children}
  </div>
}


export const ProtectedRoute = ({ children } :any) => {
  const context = useOutletContext();
  const { isLogged } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const [logged, setLogged] = useState(false)
  useEffect(()=>{
    isLogged().then(()=>{
      console.log('[protectedroute] user logged, continue..')
      setLogged(true)
    }).catch(()=>{
      console.log('[protectedroute] user not logged redirec to /login')
      navigate('/login') 
    })
  }, [location])

  return logged? <Outlet context={context} /> : <div>Authorization...</div>;
};
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";
import { Content, Page } from "../../layouts/frame";
import { Section } from "../../lib/components/content/block";
import { ControlledForm } from "../../lib/components/form";
import { CheckboxInput, PasswordInput, SubmitButton, TextInput } from "../../lib/components/form/input";
import { ProgressiveImage } from "../../lib/components/image";
import { SmallText, SubTitle, Title } from "../../lib/components/typo";
import { RegisterUser } from "../../lib/api";


export function RegisterPage(){
    interface FormValues { email: string, password: string, token: string }

    const form = useForm<FormValues>()

    const handleSubmit = useCallback((data: FormValues) => {
        RegisterUser({...data, token: 'no-token'})
        .then((r: any) => {
          console.log('register result', r)
          if (!r.error) {
            window.location.href = `/confirm-account?email=${data.email}`
            return
          }
        })
      }, []); 

    return <Page>
      <Content>
              <Section>
                <ControlledForm useForm={form} onSubmit={form.handleSubmit(handleSubmit)}>
                    <div className={'flex flex-col justify-center items-center p-2 max-w-160 m-auto'}>
                    <ProgressiveImage src="/img/furry_journey_transparent_white.png" className="bg-white h-44"/>
                    <Title>Bienvenue!</Title>
                    <SubTitle>Créez maintenant votre compte</SubTitle>
                    <TextInput useForm={form} required={true} placeholder={"Nom public"} name={"display_name"}/>
                    <TextInput useForm={form} required={true} placeholder={"Email"} name={"email"}/>
                    <PasswordInput useForm={form} required={true} placeholder={"Mot de passe"} name={"password"}/>
                    <CheckboxInput useForm={form} required={true} label="J'accepte les conditions générales d'utilisation"/>
                    <SubmitButton useForm={form} label={"Connexion"}/>
                    <SmallText>Vous avez un problème de connection? <NavLink to={"/account-recovery"} className={"text-[#1476dd]"}>Cliquez-ici</NavLink></SmallText>
                    <div className="bg-default w-full h-[2px] m-6"></div>
                    <SubTitle>Déjà inscrit?  <NavLink to={"/login"} className={"text-[#1476dd]"}>Connexion</NavLink></SubTitle>
                  </div>
                </ControlledForm>
            </Section>
      </Content>
      {/* <AsideFragment>
        <Section>
          
        </Section>
      </AsideFragment> */}
    </Page>
}
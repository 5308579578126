import { useState } from "react"
import { LoginUser } from "../api";
import { errors, parseError } from "../api/errors";
import { Preferences } from '@capacitor/preferences';

export function useUser() {
    const login = ({ email, password }: { email: string, password: string }) => {
        return LoginUser({ email, password, token: 'no-token' }).then((r: any) => {
            console.log('execute login response', r)
            if (r && !r.error) {
                return Preferences.set({
                    key: 'current_user',
                    value: JSON.stringify(r)
                })
            }
        })
            .catch((e: any) => {
                const err = parseError(e)
                if (err == errors.confirm_email) {
                    window.location.href = `/confirm-account?email=${email}`
                    return
                }
            })
    }

    return {
        getUser: () => {
            return Preferences.get({ key: 'current_user' }).then(u => u.value ? JSON.parse(u.value) : Promise.reject('not_logged'))
        },
        isLogged: async () => {
            const u = await Preferences.get({ key: 'current_user' })
            return u.value ? Promise.resolve(JSON.parse(u.value)) : Promise.reject('not_logged')
        },
        login: login
    }
}


import classNames from "classnames"
import { HTMLAttributes, PropsWithChildren } from "react"
import { NavLink } from "react-router-dom"

interface SectionProps extends PropsWithChildren<HTMLAttributes<HTMLElement>> {

}

export function Section(props: SectionProps): JSX.Element {
  return <section {...props} className={classNames("p-2", props.className)} />
}


export function StyledPage({ html }: { html: string }) {
  return <div dangerouslySetInnerHTML={{ __html: html }} />
}


export function Article({ img, title, date, description, link, badges }: {
  img: string,
  title: string,
  date?: string,
  description?: string,
  link: string,
  badges?: { name: string }[]
}) {
  return <NavLink to={link} title={title}>
    <article className="relative overflow-hidden rounded-lg shadow transition hover:shadow-lg border border-gray-200">
      <img
        alt=""
        src={img}
        className="absolute inset-0 h-full max-h-36 w-full object-cover"
      />

      <div className="relative  pt-32 sm:pt-32 lg:pt-32">
        <div className="p-4 sm:p-6">
          {/* {date && <time dateTime={date} className="block text-xs ">{DateFormat({date: date})}</time>} */}

          <h3 className="mt-0.5 text-lg line-clamp-1">{title}</h3>

          {description && <p className="mt-2 line-clamp-3 text-sm/relaxed ">
            {description}
          </p>}
          {/* <div>{badges && badges.map(b => <BadgeDot key={'badge-' + title + '-' + b.name}
                                                      className="bg-blue-50 border-blue-400 text-blue-600">{b.name}</BadgeDot>)}</div> */}
        </div>
      </div>
    </article>
  </NavLink>
}
import { Capacitor } from "@capacitor/core";
import { createPortal } from "react-dom";
import { NavLink, useNavigate, useOutletContext } from "react-router-dom";

export function BackNavLink({to}: {to: string}){
    
    const {backNavRef} = useOutletContext<any>();
    const navigate  = useNavigate()
    const handleClick = ()=>{
        return navigate(to)
    }

    const renderBack = ()=> {
        return Capacitor.getPlatform() === 'ios' || Capacitor.getPlatform() === 'android' || Capacitor.getPlatform() === 'web' &&  <div onClick={()=>handleClick()} className="justify-center items-center md:hidden hover:bg-gray-100 w-[80px] h-[55px]">
        <div  className="block p-4  text-[#1476dd]">Retour</div>
        </div>
    }
    
    return (backNavRef && createPortal(renderBack(), backNavRef))
}
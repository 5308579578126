export const errors = {
  'internal_server_error': 'internal_server_error',
  'confirm_email': 'confirm_email',
  'user_already_exists': 'user_already_exists',
  'invalid_email_format': 'invalid_email_format',
  'email_already_exists': 'email_already_exists',
  'invalid_password': 'invalid_password',
  'password_too_short': 'password_too_short',
  'password_too_long': 'password_too_long',
  'passwords_not_matches': 'passwords_not_matches',
  'invalid_old_password': 'invalid_old_password',
  'invalid_password_confirmation': 'invalid_password_confirmation',
  'invalid_password_confirmation_code': 'invalid_password_confirmation_code',
  'invalid_email_confirmation_code': 'invalid_email_confirmation_code',
  'reset_password': 'reset_password',
  'invalid_credentials': 'invalid_credentials',
  'invalid_recaptcha': 'invalid_recaptcha',
  'account_locked_because_legacy_password': 'account_locked_because_legacy_password',
  'user_using_oauth_provider': 'user_using_oauth_provider',
}

export function parseError(e: any) {
  return e?.data?.error
}

export function displayError(e: string): string {
  switch (e) {
    case errors.confirm_email:
      return 'Veuillez confirmer votre email'
    case errors.reset_password:
      return 'Veuillez terminer la réinitialisation de votre mot de passe'
    case errors.invalid_recaptcha:
      return 'Erreur de vérification que vous n\'êtes pas un robot'
    case errors.invalid_email_format:
      return 'Format de l\'email incorrect'
    case errors.user_already_exists:
      return 'Cet utilisateur existe déjà'
    case errors.email_already_exists:
      return 'Cet adresse email existe déjà'
    case errors.invalid_password:
      return 'Le mot de passe de respecte pas les conditions nécessaires'
    case errors.password_too_short:
      return 'Le mot de passe est trop court'
    case errors.password_too_long:
      return 'Le mot de passe est trop long'
    case errors.passwords_not_matches:
      return 'Les mots de passe sont différents'
    case errors.invalid_old_password:
      return 'Le mot de passe actuel est incorrect'
    case errors.invalid_password_confirmation:
      return 'Les mots de passe sont différents'
    case errors.invalid_password_confirmation_code:
    case errors.invalid_email_confirmation_code:
      return 'Le code de confirmation est incorrect'
    case errors.invalid_credentials:
      return 'Les identifiants sont incorrects'
    case errors.account_locked_because_legacy_password:
      return 'Compte bloqué. Veuillez réinitialiser votre mot de passe'
    case errors.user_using_oauth_provider:
      return 'Votre compte est attaché à un fournisseur (google, facebook). Veuillez l\'utiliser pour vous connecter.'
    case errors.internal_server_error:
      return 'Une erreur inattendue est survenue. Veuillez réessayer ultérieurement'
  }
  return e
}
import { DynamicFragment, FragmentType } from "../../../layouts/fragments/hydratror"
import { Section } from "../../../lib/components/content/block"
import { Grid, GridSkeleton } from "../../../lib/components/image/gridimage"
import { Title, TitleSkeleton } from "../../../lib/components/typo"

export function LodgingListFragment({ sections, petCategories, typeCategories}: { filters:any, sections:any, petCategories:any, typeCategories:any}){
    return <>
    {!sections && Array.from({ length: 5 }, _ => <Section><TitleSkeleton /><GridSkeleton /></Section>)}

    {sections && sections.sections.map((section: any) => (
      <Section key={section.title} >
        <Title>{section.title}</Title>

        <Grid>
          {section.items.map((s: any) => {
            return DynamicFragment(FragmentType.CardElement, {
              key: section.title + s.id,
              img: s.media_url,
              badge: s.type,
              href: '/lodgings/' + s.id,
              line1: s.title,
              line2: s.pets,
              line3: s.location_department_name + ', ' + s.location_city_name
            })
          })}
        </Grid>
      </Section>
    ))
    }

    {petCategories && petCategories.sections.map((t: any) => <Section key={t.id}>
      <Title>{t.title}</Title>

      <div className=" bg-gray-200 p-3 rounded-lg">
        {t.items.map((s: any) => {
          return DynamicFragment(FragmentType.Badge, {
            className: "bg-white hover:bg-gray-100 block w-full text-center my-2 md:mx-2 md:px-3 md:w-fit",
            key: s.id,
            href: '/pets?pet=' + s.id,
            label: s.title,
          })
        })}
      </div>
    </Section>
    )
    }

    {typeCategories && typeCategories.sections.map((t: any) => <Section key={t.id}>
      <Title>{t.title}</Title>

      <div className="bg-gray-200 p-3 rounded-lg">
        {t.items.map((s: any) => {
          return DynamicFragment(FragmentType.Badge, {
            className: "bg-white  hover:bg-gray-100 block w-full text-center my-2 md:mx-2 md:px-3 md:w-fit",
            key: t.title + s.id,
            href: '/pets?type=' + s.id,
            label: s.title,
          })
        })}
      </div>
    </Section>
    )
    }

  </>
}
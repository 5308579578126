import {
  Feedback,
  ObjectType,
  ProspectingRelation,
  ProspectingTemplate,
  ProspectingUser,
  TrendMetric
} from "./types";
import { get } from "./fetch";

export const GetTrendMetric = ({objectType}: {
  objectType: ObjectType
}) => get<TrendMetric>(`/v1/${objectType}/metrics/trend`, undefined)

export const GetProspectingUsers = () => get<ProspectingUser[]>(`/v1/users/prospecting/users`, [])
export const GetProspectingTemplates = () => get<ProspectingTemplate[]>(`/v1/users/prospecting/templates`, [])
export const GetProspectingRelations = () => get<ProspectingRelation[]>(`/v1/users/prospecting/relations`, [])
export const GetFeedbacks = () => get<Feedback[]>(`/v1/feedbacks`, [])

import { ScreenOverlayFragment } from "../../../layouts/fragments/create";
import { Button } from "../../../lib/components/button";
import { Section } from "../../../lib/components/content/block";
import { TextareaInput } from "../../../lib/components/form/input";

export function CreateEventFragment(){
    return <ScreenOverlayFragment title='Publier sur un évènement'>

    <Section>
      <div className="border p-3 text-blue-700">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Emplacement</label>
        (O) Position courante
      </div>
    </Section>

    <Section>
      <div className="border p-3 ">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Catégorie</label>
        (O) Sortie locale
      </div>
    </Section>

    <Section>
      <div className="border p-3 ">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Ajouter des photos</label>
        (O) Cliquez ici
      </div>
    </Section>

    
    <Section>
      <div className="border p-3 ">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Dates</label>
        (O) 23/09/2024 08h00 au 27/09/2024 18h00
      </div>
    </Section>


    <Section>
      <TextareaInput />
    </Section>

    <Section>
      <Button>Publier</Button>
    </Section>
  </ScreenOverlayFragment>
}
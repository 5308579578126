import classNames from "classnames"
import { ButtonHTMLAttributes, PropsWithChildren, useCallback, useEffect } from "react"
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { UseFormReturn } from "react-hook-form"

interface ControlledFormProps extends PropsWithChildren {
  useForm: UseFormReturn<any, any>
  onSubmit: (data: any) => void
}

export const ControlledForm = ({ useForm, onSubmit, children }: ControlledFormProps) => {
 


  return (
    // <GoogleReCaptchaProvider reCaptchaKey="6Lc3nnQpAAAAAET8qUNuTLnk6JBTPEx2r-MOW3MH">
    <ControlledRecaptchaForm  useForm={useForm} onSubmit={useForm.handleSubmit(data => onSubmit({ data }))}>
      {children}
    </ControlledRecaptchaForm>
    // </GoogleReCaptchaProvider>
  )
}


export const ControlledRecaptchaForm = ({ useForm,  onSubmit, children }: {useForm:any, onSubmit:any, children:any}) => {
  // const { executeRecaptcha } = useGoogleReCaptcha();

  // // Create an event handler so you can call the verification on button click event or form submit
  // const handleReCaptchaVerify = useCallback(async () => {
  //   if (!executeRecaptcha) {
  //     console.log('Execute recaptcha not yet available');
  //     return;
  //   }

  //   const token = await executeRecaptcha('yourAction');
  //   useForm.setValue("token", token)
  //   // Do whatever you want with the token
  // }, [executeRecaptcha]);

  // // You can use useEffect to trigger the verification as soon as the component being loaded
  // useEffect(() => {
  //   handleReCaptchaVerify();
  // }, [handleReCaptchaVerify]);

  return (
    <form className="w-full" onSubmit={e => onSubmit(e)}>
      {children}
    </form>)
}

interface ControlledSubmitProps extends PropsWithChildren<ButtonHTMLAttributes<any>> {
  useForm: UseFormReturn<any, any>
}


export function getLabel(label?: string, required?: boolean, help?: string) {
  return required === true && label ? <>{label} <span className="text-gray-400 text-sm">(obligatoire)</span></> : label;
}

export function getRules(label?: string, required?: boolean) {
  return { required: required ? 'champs requis' : undefined };
}

export function getFormErrorMessage(errors: any, name: string) {
  return errors[name] && <span className="text-red-600 text-sm">{errors ? errors[name]!.message : 'Error'}</span>
}

export type ControlledProps<T> = PropsWithChildren<T> & {
  label?: string;
  help?: string;
  name?: string
  useForm: UseFormReturn<any, any>
  required?: boolean
  defaultValue?: any
}
export const Field = (props: any) => <div {...props} className={classNames(props.className, "field m-2 pr-4 flex flex-col w-full")} />
export const Label = (props: ControlledProps<{}>) => props.label ? <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{props.label}</label> : <></>
export const Helper = (props: ControlledProps<{}>) => props.help ? <p id="helper-text-explanation" className="mt-2 text-sm text-gray-500 dark:text-gray-400">{props.help}</p> : <></>


import { NavLink } from "react-router-dom";
import { Page } from "../frame";
import { GlobeEuropeAfricaIcon, HomeIcon, PlusIcon, FireIcon, MapPinIcon, HomeModernIcon, CalendarIcon, NewspaperIcon, MegaphoneIcon, TvIcon, UserGroupIcon, ShoppingBagIcon, Bars3Icon, EllipsisHorizontalCircleIcon } from '@heroicons/react/24/outline'
import { MenuItem, MenuLink } from "../menu";
import { Title } from "../../lib/components/typo";
import { useEffect, useState } from "react";
import { GetMenu } from "../../lib/api";

const items: MenuItem[] = [
    {
      key: 'lodgings', href: '/lodgings', items: [], icon: <HomeModernIcon height={22} />
    },
    {
      key: 'pages', href: '/pages', items: [], icon: <NewspaperIcon height={22} />
    },
    {
      key: 'events', href: '/events', items: [], icon: <CalendarIcon height={22} />
    },
    {
      key: 'services', href: '/services', items: [], icon: <MegaphoneIcon height={22} />
    },
    {
      key: 'marketplace', href: '/marketplace', items: [], icon: <ShoppingBagIcon height={22} />,
    },
    {
      key: 'TV', href: '/tv', roles: ['system:administrator'], items: [], icon: <TvIcon height={22} />
    },
  ]
  
export function MoreMobilePage(){
  const [menu, setMenu] = useState<any>([])
  useEffect(()=> {
    GetMenu().then(s => {
      if (!Array.isArray(s)){
        return
      }
      setMenu(s.filter((i:any) => i.toolbar == -1 ))
    })
  },[])

  if(!menu){
    return <Page>Chargement</Page>
  }
    return <Page>
        <Title>Plus de services</Title>
        <ul>
          <li>
            {menu && menu.map(((it:any) => {
              const allowed = true //it.roles ? profile && hasRequiredRoles(it.roles, profile.roles ?? []) : true
              return allowed && <div key={'m-i-' + it.name}>
                <div className="flex flex-row w-full justify-end">
                  <div className="grow"><MenuLink to={it.href} >
                      <span className="w-6"><i dangerouslySetInnerHTML={{__html: it.icon}}/></span>
                    {it.name ?? it.key}
                  </MenuLink>
                  </div>
                </div>
              </div>
            }))}
          </li>
        </ul>
    </Page>
}